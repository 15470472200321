import { createGlobalState } from 'react-hooks-global-state';
import { EnumConnectionType, EnumFilterType, EnumPlanType, EnumSortBy, EnumViewPlansLargeType, EnumViewPlansType, IAuditVisitor, IGlobals, IFilters, IPlanFilterParams, ISession, EnumPlanTechnologyType } from './contracts/types';

const dataPlanFilterParams = sessionStorage.getItem('data_iPlanFilterParams');
const dataFilters = sessionStorage.getItem('data_iFilters');
const dataGlobals = sessionStorage.getItem('data_iGlobals');
const dataSession = localStorage.getItem('data_iSession');
const dataAuditVisitor = sessionStorage.getItem('data_iAuditVisitor');

const target = document.getElementById('wr-root');
let wiresurferApi = '';
let imagesUrl = '';
if (target !== null) {
    const id = target.dataset.id ? target.dataset.id : '';
    const wrSettings = (window as any).wrSettings[id];
    wiresurferApi = wrSettings.wiresurferApi;
    imagesUrl = wrSettings.imagesUrl;
}

let doubleSla = window.location.href.indexOf('//');
let beforeUrl = window.location.href.substring(0, doubleSla + 2);
let domain = window.location.href.replace(beforeUrl, '');
let oneSla = domain.indexOf('/');
let baseUrl = beforeUrl + domain + '/';
let isLocalhost = false;

if (oneSla !== -1) {
    baseUrl = beforeUrl + domain.substring(0, oneSla + 1);
}

if (baseUrl.indexOf('localhost:') !== -1) {
    isLocalhost = true;
}

isLocalhost = false;

const initialState: IPlanFilterParams = dataPlanFilterParams
    ? JSON.parse(dataPlanFilterParams)
    : {
        baseUrl: baseUrl,
        isLocalhost: isLocalhost,
        'wiresurferApi': wiresurferApi,
        'imagesUrl': imagesUrl,
        surveyData: {
            isOpen: false,
            planId: 0,
            orderId: -1,
            isPageThanks: false,
            encryptOrderId: '',
            urlOrderSummary: '',
        },
        surveyResponse: {
            orderId: -1,
            surveyQuestionSectionDTO: [],
            surveyQuestionsResponse: [],
            orderAddressesDTO: [],
            isFinish: false,
            urlOrderSummary: '',
        },
        internetParams: {
            streetLine: '',
            city: '',
            zipCode: '-1',
            address: '',
            addressDetail: '',
            latitude: -1,
            longitude: -1,
            selectAddress: false,
            openedGroupAddressPlans: false,
            viewPlansType: EnumViewPlansType.SHORT,
            country: 'US',
            smartyAddress: null,
            plan: {
                planId: 0,
                productId: 0,
                internalPlanId: 0,
                planName: '',
                planServiceTypeCode: '',
                planTypeCode: EnumPlanType.BUSINESS_INTERNET,
                planTypeName: '',
                providerId: 0,
                providerName: '',
                providerLogo: '',
                planRated: 0,
                planFiberFastQualType: '',
                planTechnologyCode: EnumPlanTechnologyType.CABLE,
                planTechnologyName: '',
                planDownloadSpeed: 0,
                planUploadSpeed: 0,
                planTerm: 0,
                planModemIncluded: false,
                planPrice: 0,
                planInstallPrice: 0,
                planLatitude: 0,
                planLongitude: 0,
                planNote: '',
                planHidden: false,
                planServiceTypeName: '',
                learnMore: '',
                codeProvider: ''
            }
            //plansByCodeProvider: []
        },
        voiceParams: {
            filterNumber: 1,
            filterPrice: { min: 0, max: 0 },
            viewPlansLargeType: EnumViewPlansLargeType.DETAILS,
            sortBy: EnumSortBy.NONE,
            filterConfigSubProducts: [],
            equipmentConfig: [],
        },
        bundleParams: {
            streetLine: '',
            city: '',
            zipCode: '-1',
            address: '',
            addressDetail: '',
            latitude: -1,
            longitude: -1,
            selectAddress: false,
            openedGroupAddressPlans: false,
            viewPlansType: EnumViewPlansType.SHORT,
            country: 'US',
            smartyAddress: null,
        },
        multipleAddresses: {
            multipleInternetParams: [],
            connectionType: EnumConnectionType.NOTHING,
            openedBulkEdit: false,
        },
        parameters: {
            CALEndPoint: '',
        },
        idAddressMultiple: -1,
        multiplePlans: [],
        someSelectedAddress: false,
        selectedAllAddress: false,
        nextPageOrder: false,
        isClickCalendly: false,
        billingAddress: '',
        billingAddresSelected: '',
        billingSmarty: null,
        verifyQuestionResponse: false,
        smartyAddressInternet: null,
        smartyAddressBundle: null,
        subProductsPlan: [],
        groupByTerms: [],
        groupByProviders: [],
        numberBestPlansPerAddress: 5,
    };

const initialFilters: IFilters = dataFilters
    ? JSON.parse(dataFilters)
    : {
        sortBy: EnumSortBy.NONE,
        sortByCompare: EnumSortBy.NONE,
        filterPrice: { min: 0, max: 0 },
        filterSpeed: { min: 0, max: 0 },
        filterTerm: [],
        filterPlanFeatures: [],
        filterProvider: [],
        filterOnlyBestPerAddress: false,
        viewPlansLargeTypeInternet: EnumViewPlansLargeType.COMPACT,
        planTypeCode: EnumPlanType.BUSINESS_INTERNET,
        showMoreLocations: false,
        plansCompareInternet: [],
        plansCompareVoice: [],
        plansCompareBundle: [],
        plansCompareMultiple: [],
        tabShoppingCart: EnumPlanType.BUSINESS_INTERNET,
        showClickToDial: false,
    };

const initialGlobals: IGlobals = dataGlobals
    ? JSON.parse(dataGlobals)
    : {
        carriers: [],
        productProperties: [],
        planLearMore: null,
    };

const initialSession: ISession = dataSession
    ? JSON.parse(dataSession)
    : {
        access_token: '',
        expires_in: 0,
        refresh_token: '',
        scope: '',
        token_type: '',
        isGoogle: false,
    };

const initialAuditVisitor: IAuditVisitor = dataAuditVisitor
    ? JSON.parse(dataAuditVisitor)
    : {
        id: -1,
        ip: '',
        search: {
            id: -1,
            visitorId: -1,
            seats: 0,
            compares: [],
        },
        searchMultiple: [],
        idMultiple: -1,
        searchFilter: {
            id: -1,
            visitorSearchId: -1,
            filterType: EnumFilterType.NONE,
            value1: '',
            value2: '',
        },
        searchSortBy: {
            id: -1,
            visitorSearchId: -1,
        },
        searchCompare: {
            id: -1,
            visitorSearchId: -1,
        },
        compareFeature: {
            id: -1,
            visitorSearchCompareId: -1,
            value: 0,
            arrayCompares: [],
        },
        compareEquipment: {
            id: -1,
            visitorSearchCompareId: -1,
            voiceEquipmentId: -1,
            countEquipments: 0,
            arrayCompares: [],
        },
        queryString: window.location.search,
        referred: ''
    };

export const { useGlobalState } = createGlobalState({
    planFilterParams: initialState,
    filters: initialFilters,
    globals: initialGlobals,
    session: initialSession,
    auditVisitor: initialAuditVisitor,
});
