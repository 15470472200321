import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Search from "../components/search.comp"
import Plans from "../components/plan/plans.comp"
import Survey from "../components/survey/survey.comp"
import { EnumViewPlansType, IWrSettings } from "../contracts/types";
import SearchOrder from '../components/searchOrder.comp';
import Multiple from '../components/multiple/multiple.comp';
import TBIOrders from '../components/tbi/listOrders';
import Identity from '../components/account/account.comp';
import Test from '../components/test.comp';
import Calculator from '../components/calculator/calculator.comp';
import ClickToDial from '../components/tbi/clickToDIal/clickToDial';
import ShoppingCart from '../components/shoppingCart/shoppingCart.comp';
import Compare from '../components/plan/compare/compare.comp';
import CompareCards from '../components/plan/compare/compareCards.comp';
import useAuditGetIp from '../hooks/api/useAuditGetIp';
import useAuditSaveVisitor from '../hooks/api/useAuditSaveVisitor';
import { useGlobalState } from '../state';
import LearnMore from '../components/plan/learnMore/learnMore.com';

interface ISearchPageProps {
    wrSettings: IWrSettings;
}

const SearchPage: React.FC<ISearchPageProps> = ({ wrSettings }) => {
    const [iAuditVisitor] = useGlobalState('auditVisitor');
    const [saveVisitor, setSaveVisitor] = useState(false);
    const [saveReferred, setSaveReferred] = useState<string>('-1');

    const { data: DataAudit } = useAuditGetIp();
    const { } = useAuditSaveVisitor(iAuditVisitor, saveVisitor, saveReferred);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const referred = query.get('r');
        setSaveReferred(referred ? referred : '');
    }, [DataAudit]);

    useEffect(() => {
        setTimeout(function () {
            if (saveReferred !== '-1') {
                setSaveVisitor(true);
            }
        }, 100);
    }, [saveReferred]);

    const divSearch = document.getElementById('wr-search');
    const divResultShort = document.getElementById('wr-result-short');
    const divResultLarge = document.getElementById('wr-result-large');
    const divCompare = document.getElementById('wr-compare');
    const divCompareCards = document.getElementById('wr-compare-cards');
    const divSurvey = document.getElementById('wr-survey');
    const divSearchOrder = document.getElementById('wr-order-summary');
    const divTBI = document.getElementById('wr-tbi');
    const divMultiple = document.getElementById('wr-multiple');
    const divAccount = document.getElementById('wr-account');
    const divTest = document.getElementById('wr-test');
    const divCalculator = document.getElementById('wr-calculator');
    const divClickToDial = document.getElementById('wr-click-to-dial');
    const divShoppingCart = document.getElementById('wr-shopping-cart');
    const divLearnMore = document.getElementById('wr-learn-more');

    return (
        <>
            {
                divSearch && ReactDOM.createPortal(<Search isPageCompare={false} />, divSearch)
            }
            {
                divSurvey && window.location.pathname.includes('quotenow') &&
                ReactDOM.createPortal(<Survey wrSettings={wrSettings} />, divSurvey)
            }
            {
                divSearchOrder && window.location.pathname.includes('quotesummary') &&
                ReactDOM.createPortal(<SearchOrder />, divSearchOrder)
            }
            {
                divTBI && (window.location.pathname.includes('tbi')) &&
                ReactDOM.createPortal(<TBIOrders />, divTBI)
            }
            {
                divResultShort && !window.location.pathname.includes('search') && !window.location.pathname.includes('multiple') &&
                ReactDOM.createPortal(<Plans viewPlansType={EnumViewPlansType.SHORT} wrSettings={wrSettings} />, divResultShort)
            }
            {
                divResultLarge && (window.location.pathname.includes('search')) &&
                ReactDOM.createPortal(<Plans viewPlansType={EnumViewPlansType.LARGE} wrSettings={wrSettings} />, divResultLarge)
            }
            {
                divCompare && window.location.pathname.includes('compare') &&
                ReactDOM.createPortal(<Compare />, divCompare)
            }
            {
                divCompareCards && window.location.pathname.includes('compare') &&
                ReactDOM.createPortal(<CompareCards />, divCompareCards)
            }
            {
                divMultiple && window.location.pathname.includes('multiple') &&
                ReactDOM.createPortal(<Multiple wrSettings={wrSettings} />, divMultiple)
            }
            {
                divAccount && window.location.pathname.includes('account') &&
                ReactDOM.createPortal(<Identity />, divAccount)
            }
            {
                divTest && window.location.pathname.includes('test') &&
                ReactDOM.createPortal(<Test />, divTest)
            }
            {
                divCalculator &&
                ReactDOM.createPortal(<Calculator />, divCalculator)
            }
            {
                divClickToDial &&
                ReactDOM.createPortal(<ClickToDial />, divClickToDial)
            }
            {
                divShoppingCart &&
                ReactDOM.createPortal(<ShoppingCart />, divShoppingCart)
            }
            {
                divLearnMore && window.location.pathname.includes('learnmore') &&
                ReactDOM.createPortal(<LearnMore />, divLearnMore)
            }
        </>
    );
}

export default SearchPage;