import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints, NUM_RESULT_PLAN_TYPE_SHORT } from '../../api';
import { IResponseParamsVoicePlansDTO, IResponseVoicePlansDTO, EnumVoicePlanFeatures, EnumSortBy, EnumViewPlansType, IRequestVoicePlansDTO } from '../../contracts/types';
import { useGlobalState } from '../../state';

export default function useVoicePlans(viewPlansType: EnumViewPlansType) {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals, setIGlobals] = useGlobalState('globals');
    const [iFilters, setIFilters] = useGlobalState('filters');
    const api = useServiceApi();

    const doRequest = async (): Promise<IResponseParamsVoicePlansDTO> => {
        let city = '';

        if (viewPlansType === EnumViewPlansType.SHORT) {
            const path = window.location.pathname;

            if (path.includes('search') || path.includes('business-internet')) {
                const responseParams: IResponseParamsVoicePlansDTO = {
                    lstCarriers: [],
                    lstProductProperties: [],
                    lstResponseVoicePlansDTO: [],
                };

                return responseParams;
            } else if (path.includes('business-phone')) {
                const pathCity = path.replace('/business-phone/', '').replace('/', '');
                if (pathCity !== '') {
                    city = pathCity;
                }
            }
        }

        const requestPlansDTO: IRequestVoicePlansDTO = {
            'city': city,
            'viewPlansType': viewPlansType,
        };

        const { data } = await api.post<IResponseParamsVoicePlansDTO>(`${ApiEndPoints.GetPlansVoice}`, requestPlansDTO);

        let arrayCarriers = iGlobals.carriers;
        data.lstCarriers.forEach((carrierDTO) => {
            const findCarrier = arrayCarriers.find((c) => c.id === carrierDTO.id);
            if (!findCarrier) {
                arrayCarriers.push(carrierDTO);
            }
        });

        let arrayProPro = iGlobals.productProperties;
        data.lstProductProperties.forEach((productPropertyDTO) => {
            const findProPro = arrayProPro.find((pp) => pp.id === productPropertyDTO.id);
            if (!findProPro) {
                arrayProPro.push(productPropertyDTO);
            }
        });

        const termsGroupBy = data.lstResponseVoicePlansDTO.map((item: IResponseVoicePlansDTO) => item.term).filter((value: any, index: any, self: any) => self.indexOf(value) === index);

        const idPlansCompare = iFilters.plansCompareVoice?.filter((id) => data.lstResponseVoicePlansDTO.filter((e) => e.planId === id).length > 0);

        setIPlanFilterParams({
            ...iPlanFilterParams,
            voiceParams: {
                ...iPlanFilterParams.voiceParams,
                'termsGroupBy': termsGroupBy,
            },
        });

        setIFilters({
            ...iFilters,
            plansCompareVoice: idPlansCompare,
        });

        setIGlobals({
            ...iGlobals,
            'carriers': arrayCarriers,
            'productProperties': arrayProPro,
        });

        return data;
    };

    const filterData = (data: IResponseParamsVoicePlansDTO) => {
        let result = data.lstResponseVoicePlansDTO.map((plan: IResponseVoicePlansDTO) => {
            let sumPriceSubProduct = 0;
            let sumPriceEquipments = 0;

            if (iPlanFilterParams.voiceParams.filterConfigSubProducts && iPlanFilterParams.voiceParams.filterConfigSubProducts.length > 0) {
                iPlanFilterParams.voiceParams.filterConfigSubProducts.forEach((sp) => {
                    const planSubProduct = sp.plans.find((p) => p.id === plan.planId);

                    if (planSubProduct) {
                        const planSubProVal = planSubProduct.values.sort((a, b) => {
                            return a.quantity - b.quantity;
                        });

                        let unitPrice = 0;
                        planSubProVal.forEach((ps) => {
                            if (sp.filterConfig >= ps.quantity) {
                                unitPrice = ps.unitPrice;
                            }
                        });

                        sumPriceSubProduct += sp.filterConfig * unitPrice;
                    }
                });
            }

            if (iPlanFilterParams.voiceParams.equipmentConfig && iPlanFilterParams.voiceParams.equipmentConfig.length > 0) {
                iPlanFilterParams.voiceParams.equipmentConfig.forEach((ec) => {
                    const planEquipment = ec.equipmentPlanConfig.find((pe) => pe.planId === plan.planId);

                    if (planEquipment) {
                        sumPriceEquipments += planEquipment.price * planEquipment.nEquipments;
                    }
                });
            }

            return { ...plan, calculatedPrice: plan.seatsTypePrice * iPlanFilterParams.voiceParams.filterNumber + sumPriceSubProduct + sumPriceEquipments };
        });

        if (iPlanFilterParams.voiceParams.filterPrice) {
            result = result.filter((plan: IResponseVoicePlansDTO) => plan.calculatedPrice >= iPlanFilterParams.voiceParams.filterPrice!.min);
            if (iPlanFilterParams.voiceParams.filterPrice!.max !== 0) {
                result = result.filter((plan: IResponseVoicePlansDTO) => plan.calculatedPrice <= iPlanFilterParams.voiceParams.filterPrice!.max);
            }
        }

        if (iPlanFilterParams.voiceParams.filterTerm && iPlanFilterParams.voiceParams.filterTerm.length > 0) {
            let resultTmp: IResponseVoicePlansDTO[];
            let resultAll: IResponseVoicePlansDTO[] = [];
            iPlanFilterParams.voiceParams.filterTerm.forEach((term) => {
                resultTmp = result.filter((plan: IResponseVoicePlansDTO) => plan.term === term && !resultAll.includes(plan));
                resultAll = resultAll.concat(resultTmp);
            });
            result = resultAll;
        }

        if (iPlanFilterParams.voiceParams.filterPlanFeatures && iPlanFilterParams.voiceParams.filterPlanFeatures.length > 0) {
            let resultAll: IResponseVoicePlansDTO[] = [];

            resultAll = result.filter((plan) => {
                let numberFeaturesValids = 0;

                iPlanFilterParams.voiceParams.filterPlanFeatures.forEach((feature) => {
                    switch (feature) {
                        case EnumVoicePlanFeatures.METERED:
                            if (plan.seatsType === 'METERED' && !resultAll.includes(plan)) {
                                numberFeaturesValids++;
                            }
                            break;
                        case EnumVoicePlanFeatures.UNLIMITED:
                            if (plan.seatsType === 'UNLIMITED' && !resultAll.includes(plan)) {
                                numberFeaturesValids++;
                            }
                            break;
                        case EnumVoicePlanFeatures.CHATIM:
                            if (plan.chatImPrice && !resultAll.includes(plan)) {
                                numberFeaturesValids++;
                            }
                            break;
                        case EnumVoicePlanFeatures.MEETVIDEO:
                            if (plan.meetVideoPrice && !resultAll.includes(plan)) {
                                numberFeaturesValids++;
                            }
                            break;
                    }
                });

                if (numberFeaturesValids === iPlanFilterParams.voiceParams.filterPlanFeatures.length) {
                    return 1;
                }

                return 0;
            });

            result = resultAll;
        }

        result.sort((a, b) => {
            switch (iPlanFilterParams.voiceParams.sortBy) {
                case EnumSortBy.LOW_TO_HIGH_PRICE:
                    return a.calculatedPrice - b.calculatedPrice;
                case EnumSortBy.HIGH_TO_LOW_PRICE:
                    return b.calculatedPrice - a.calculatedPrice;
                default:
                    return a.calculatedPrice - b.calculatedPrice;
            }
        });

        if (viewPlansType === EnumViewPlansType.SHORT) {
            data.lstResponseVoicePlansDTO = result.slice(0, NUM_RESULT_PLAN_TYPE_SHORT);
            return data;
        }

        data.lstResponseVoicePlansDTO = result;
        return data;
    };

    return useQuery<IResponseParamsVoicePlansDTO>(['plansVoice'], () => doRequest(), {
        select: filterData,
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: 1,
        notifyOnChangeProps: ['data', 'error'],
    });
}
