import React, { useEffect, useState } from "react";

import {
  IResponseInternetPlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IInternetPlanLargeMobileProps {
  plan: IResponseInternetPlansDTO;
  onChangeCompareCheckBox: (
    planId: number,
    codeProvider: string | undefined
  ) => void;
}

const InternetPlanLargeMobile: React.FC<IInternetPlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreRecommended, setSeeMoreRecommended] = useState(false);
  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreRecommended = () => {
    setSeeMoreRecommended(!seeMoreRecommended);
  };

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  return (
    <div className="row align-middle">
      <div className="col-12 p-2">
        {plan.providerLogo === "logoWiresurferDummy.png" ? (
          <img
            className="mx-auto d-block"
            src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
            alt=""
          />
        ) : (
          <img
            className="mx-auto d-block"
            src={`data:image/jpeg;base64,${
              iGlobals.carriers.find((c) => c.id === plan.providerId)
                ?.imageBase64
            }`}
            alt=""
          />
        )}
      </div>
      {/* <div className="col-6 p-2">
                <div className="card-form-check">
                    <div className={iFilters.plansCompareInternet.find(p => p.idPlan === plan.planId) ? 'circle-check-on' : 'circle-check'} onClick={() => onChangeCompareCheckBox(plan.planId, plan.codeProvider)} />
                    {
                        iFilters.plansCompareInternet && iFilters.plansCompareInternet.length >= 1
                            ? (
                                <span className="card-compareplan link-url" onClick={onClickCompareLink}>Compare Plan</span>
                            ) : (
                                <span className="card-compareplan">Compare<br />Plan</span>
                            )
                    }
                </div>
            </div> */}
      <div className="col-6 card-tr-title p-2 mobile-espace">Price</div>
      <div className="col-6 text-center p-2">
        <span className="card-price"></span>
        <span className="voice-bolt">${plan.planPrice.toFixed(2)} mo</span>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2 mobile-espace">MAXIMUM SPEED</div>
      <div className="col-6 text-center p-2">
        <div className="card-sizeSpeedMobile">
          {/* <div className="card-icon-bolt float-start" /> */}
          <div>
            {/* <div className="card-icon-down float-start" /> */}
            <span className="card-max-term speed-down">
              {plan.planDownloadSpeed >= 1000
                ? (plan.planDownloadSpeed / 1000).toFixed(0)
                : plan.planDownloadSpeed}
              {plan.planDownloadSpeed >= 1000 ? " Gbps" : " Mbps"}
            </span>
            {/* <div className="card-icon-mbps float-end" /> */}
          </div>
          <div>
            {/* <div className="card-icon-up float-start" /> */}
            <span className="card-max-term speed-up">
              {plan.planUploadSpeed >= 1000
                ? (plan.planUploadSpeed / 1000).toFixed(0)
                : plan.planUploadSpeed}
              {plan.planUploadSpeed >= 1000 ? " Gbps" : " Mbps"}
            </span>
            {/* <div
              className={`${
                plan.planUploadSpeed >= 1000
                  ? "card-icon-gbps"
                  : "card-icon-mbps"
              } float-end`}
            /> */}
          </div>
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">TERM</div>
      <div className="col-6 text-center p-2">
        {/* <span className="card-max-term"></span> */}
        <div className="voice-bolt">{plan.planTerm} MONTHS</div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 card-tr-title p-2 text-center conect-device-m">
        CONNECTED DEVICES
      </div>
      <div className="col-12 text-center p-2 text-center conect-device-m-b">
        <button
          type="button"
          className="btn card-button-connect card-mobile-btn-learmore"
        >
          LEARN MORE
        </button>
      </div>
    </div>
  );
};

export default InternetPlanLargeMobile;
