import React, { useEffect, useState } from 'react';
import { EnumConnectionType, EnumPlanType, EnumSortBy, EnumViewPlansLargeType, EnumViewPlansType, IPageSurveyDTO, IQuestionDTO, IWrSettings } from '../../contracts/types';
import { useGlobalState } from '../../state';
import Question from './question/question.comp';
import parse from 'html-react-parser';
import useGetParameters from '../../hooks/api/useGetParameters';

interface IWizardPageProps {
    wrSettings: IWrSettings;
    pageSurveyDTO?: IPageSurveyDTO;
    isLastPage: boolean;
}

const WizardPage: React.FC<IWizardPageProps> = ({ wrSettings, pageSurveyDTO, isLastPage }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');
    const [urlOrderSummary, setUrlOrderSummary] = useState('');

    let arrayQuestions = pageSurveyDTO?.questionsDTO;

    if (iFilters.planTypeCode !== EnumPlanType.BUSINESS_BUNDLES) {
        arrayQuestions = iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET ? pageSurveyDTO?.questionsDTO.filter((q) => q.internet) : pageSurveyDTO?.questionsDTO.filter((q) => q.voice);
    }

    const onClickHome = () => {
        setIPlanFilterParams({
            ...iPlanFilterParams,
            surveyData: {
                ...iPlanFilterParams.surveyData,
                isOpen: false,
                planId: 0,
                orderId: -1,
                isPageThanks: false,
                encryptOrderId: '',
                urlOrderSummary: '',
                providerId: 0,
                internalPlanId: 0,
            },
            surveyResponse: {
                ...iPlanFilterParams.surveyResponse,
                orderId: -1,
                surveyQuestionsResponse: [],
                orderAddressesDTO: [],
                isFinish: false,
                urlOrderSummary: '',
                encryptOrderId: '',
                planTypeCode: null,
            },
            multipleAddresses: {
                ...iPlanFilterParams.multipleAddresses,
                multipleInternetParams: [],
                connectionType: EnumConnectionType.NOTHING,
                openedBulkEdit: false,
            },
            internetParams: {
                ...iPlanFilterParams.internetParams,
                streetLine: '',
                city: '',
                zipCode: '-1',
                state: '',
                address: '',
                addressDetail: '',
                latitude: -1,
                longitude: -1,
                selectAddress: false,
                openedGroupAddressPlans: false,
                viewPlansType: EnumViewPlansType.SHORT,
                country: 'US',
                plan: {
                    planId: 0,
                    productId: 0,
                    internalPlanId: 0,
                    planName: '',
                    planServiceTypeCode: '',
                    planTypeCode: EnumPlanType.BUSINESS_INTERNET,
                    planTypeName: '',
                    providerId: 0,
                    providerName: '',
                    providerLogo: '',
                    planRated: 0,
                    planFiberFastQualType: '',
                    planTechnologyName: '',
                    planDownloadSpeed: 0,
                    planUploadSpeed: 0,
                    planTerm: 0,
                    planModemIncluded: false,
                    planPrice: 0,
                    planInstallPrice: 0,
                    planLatitude: 0,
                    planLongitude: 0,
                    planNote: '',
                    planHidden: false,
                    planServiceTypeName: '',
                    learnMore: '',
                    codeProvider: ''
                }
            },
            voiceParams: {
                ...iPlanFilterParams.voiceParams,
                filterNumber: 5,
                filterPrice: { min: 0, max: 0 },
                viewPlansLargeType: EnumViewPlansLargeType.DETAILS,
                sortBy: EnumSortBy.LOW_TO_HIGH_PRICE,
                filterConfigSubProducts: [],
                equipmentConfig: [],
            },
            idAddressMultiple: -1,
            someSelectedAddress: false,
            selectedAllAddress: false,
            numberBestPlansPerAddress: 5,
            nextPageOrder: false,
            isClickCalendly: false,
            billingAddress: '',
            billingAddresSelected: '',
            billingSmarty: null,
            verifyQuestionResponse: false,
            smartyAddressInternet: null,
            smartyAddressBundle: null,
            subProductsPlan: [],
        });

        setIFilters({
            ...iFilters,
            'sortBy': EnumSortBy.LOW_TO_HIGH_PRICE,
            'filterPrice': { min: 0, max: 0 },
            'filterSpeed': { min: 0, max: 0 },
            'filterTerm': [],
            'filterPlanFeatures': [],
            'filterProvider': [],
            'filterOnlyBestPerAddress': false,
            'viewPlansLargeTypeInternet': EnumViewPlansLargeType.COMPACT,
            'plansCompareInternet': [],
            'plansCompareVoice': [],
            'plansCompareBundle': [],
            'plansCompareMultiple': [],
        });

        window.open(iPlanFilterParams.baseUrl, '_self');
    };

    useEffect(() => {
        setUrlOrderSummary(iPlanFilterParams.surveyData.urlOrderSummary);
    }, [iPlanFilterParams.surveyData.urlOrderSummary]);

    const { data: dataParameters } = useGetParameters();

    useEffect(() => {
        if (isLastPage) {
            const question = iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 5);

            if (question) {
                // If is Chat Tidio
                if (question.value === '3') {
                    if ((window as any).tidioChatApi) {
                        (window as any).tidioChatApi.setVisitorData({
                            distinct_id: iPlanFilterParams.surveyResponse.orderId?.toString(),
                            email: iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 4)?.value,
                            name: iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 2)?.value,
                            phone: iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 3)?.value,
                        });

                        (window as any).tidioChatApi.setContactProperties({
                            company_name: iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 1)?.value,
                            order_number: iPlanFilterParams.surveyResponse.orderId?.toString(),
                            order_url: iPlanFilterParams.surveyData.urlOrderSummary ? iPlanFilterParams.surveyData.urlOrderSummary : '',
                        });

                        (window as any).tidioChatApi.show();
                        (window as any).tidioChatApi.open();
                    }
                } else if (question.value === '2' && dataParameters) {
                    const urlOrderSummary = iPlanFilterParams.surveyData.urlOrderSummary ? iPlanFilterParams.surveyData.urlOrderSummary : '';
                    const name = iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 1)?.value;
                    const email = iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find((q) => q.questionId === 4)?.value;
                    const orderId = iPlanFilterParams.surveyData.orderId?.toString();

                    const url = dataParameters.CALEndPoint.replace('${name}', name ? encodeURI(name) : '')
                        .replace('${email}', email ? encodeURI(email) : '')
                        .replace('${a1}', orderId ? orderId : '')
                        .replace('${a2}', encodeURI(urlOrderSummary));

                    window.open(url, '_blank');
                }
            }
        }
    }, []);

    let title = '';
    let isOnlyQuestion = arrayQuestions?.length === 1 ? true : false;

    if (pageSurveyDTO && pageSurveyDTO.numPage === 1) {
        title = `<span class="orderTitle">Complete the following information to get started!</span>`;
    } else if (pageSurveyDTO && pageSurveyDTO.numPage === 2) {
        title = 'How would you like to continue?';
    } else if (pageSurveyDTO && pageSurveyDTO.numPage === 4) {
        title = 'Secondary Point of Contact:';
    } else if (pageSurveyDTO && pageSurveyDTO.numPage === 3 && arrayQuestions) {
        title = arrayQuestions[0].label;
        isOnlyQuestion = true;
    } else if (arrayQuestions && arrayQuestions.length === 1 && arrayQuestions[0].label !== '') {
        const question = arrayQuestions[0];
        title = question.label + (question.required && arrayQuestions[0].type !== 'billing' ? ' <span className="labelRequired">*</span>' : '');
    }

    return (
        <>
            {!isLastPage && (
                <>
                    <h2>{parse(title)}</h2>
                    {arrayQuestions && arrayQuestions.map((question: IQuestionDTO) => <Question key={question.id} wrSettings={wrSettings} questionDTO={question} isOnlyQuestion={isOnlyQuestion} />)}
                </>
            )}
            {isLastPage && (
                <div className="contentThank">
                    <div className="row">
                        <img src="/wp-content/wiresresources/images/2021/06/thankyou.png" />
                        <h3 className="thanksTitle">
                            Thank you for your quote at wiresurfer.com.
                            <br />
                            <a className="" href={urlOrderSummary}>
                                Quote # {iPlanFilterParams.surveyData.orderId?.toString()}
                            </a>
                        </h3>
                        <div className="thanksOrderNow">
                            {iPlanFilterParams.surveyResponse.surveyQuestionsResponse
                                .filter((q) => q.questionId === 5)
                                ?.map((q) => {
                                    if (q.value === '1') {
                                        return (
                                            <div className="thanksOrderNowText">
                                                Our customer service agents are standing by to assist with next steps.
                                                <br />
                                                Please call us at 1-855-978-7337 and give the agent quote {iPlanFilterParams.surveyResponse.orderId} to continue.
                                                <br />
                                            </div>
                                        );
                                    } else if (q.value === '2') {
                                        return <div className="thanksOrderNowText">Our customer service agent will call you to assist with next steps.</div>;
                                    } else if (q.value === '3') {
                                        return <div className="thanksOrderNowText">Our customer service agents are standing by to chat and assist with next steps.</div>;
                                    } else if (q.value === '4') {
                                        return <div className="thanksOrderNowText">We’ll email you your contract right away.</div>;
                                    }
                                })}
                            <div className="thanksOrderNowFoot">
                                Welcome to wiresurfer.com. Your area’s best telecom business service.
                                <br />
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                    <div className="row">
                        <a href="javascript:void(0)" className="btnThanks" onClick={onClickHome}>
                            Back home
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default WizardPage;
