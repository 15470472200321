import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../state";
import useAuditSaveSearch from "../../hooks/api/useAuditSaveSearch";
import {
  EnumPlanType,
  EnumSearchType,
  EnumViewPlansType,
  IPlanFilterParamsBundle,
  ISmartyResponseDTO,
} from "../../contracts/types";
import AddressText from "./addressText.comp";
import useBundlePlans from "../../hooks/api/useBundlePlans";
import useInternetPlans from "../../hooks/api/useInternetPlans";
import { dispatchDataLayer } from "../../helpers/fnGoogleTag";

interface ITabBundleProps {}

const TabBundle: React.FC<ITabBundleProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");
  const [isGoSearch, setIsGoSearch] = useState(false);
  const [addressValue, setAddressValue] = useState("");
  const [smartyResponse, setSmartyResponse] = useState<ISmartyResponseDTO>();

  const { data: dataSearch } = useAuditSaveSearch();

  const {} = useInternetPlans(EnumViewPlansType.SHORT, "", 0);

  useEffect(() => {
    if (dataSearch && isGoSearch) {
      window.open(iPlanFilterParams.baseUrl + "search", "_self");
    }
  }, [dataSearch, isGoSearch]);

  const onClickSearchBundle = (e: any) => {
    let dumSmarty = smartyResponse;
    let enterSearch = true;

    if (
      iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES &&
      !iPlanFilterParams.smartyAddressBundle &&
      iPlanFilterParams.bundleParams.address === "" &&
      iPlanFilterParams.smartyAddressInternet &&
      iPlanFilterParams.smartyAddressInternet.street_line !== ""
    ) {
      const address = `${iPlanFilterParams.smartyAddressInternet.street_line} ${iPlanFilterParams.smartyAddressInternet.city}`;
      const entries =
        iPlanFilterParams.smartyAddressInternet.entries > 1
          ? ` (${iPlanFilterParams.smartyAddressInternet.entries} more entries)`
          : "";
      const addressDetail = `${
        iPlanFilterParams.smartyAddressInternet.street_line
      }, ${
        iPlanFilterParams.smartyAddressInternet.secondary !== ""
          ? `${iPlanFilterParams.smartyAddressInternet.secondary}${entries}, `
          : ""
      }${iPlanFilterParams.smartyAddressInternet.city}, ${
        iPlanFilterParams.smartyAddressInternet.state
      } ${iPlanFilterParams.smartyAddressInternet.zipcode}`;

      setSmartyResponse(iPlanFilterParams.smartyAddressInternet);

      let newAddress: IPlanFilterParamsBundle = {
        streetLine: iPlanFilterParams.smartyAddressInternet.street_line,
        city: iPlanFilterParams.smartyAddressInternet.city,
        zipCode: iPlanFilterParams.smartyAddressInternet.zipcode,
        address: address,
        addressDetail: addressDetail,
        state: iPlanFilterParams.smartyAddressInternet.state,
        secondary: iPlanFilterParams.smartyAddressInternet.secondary,
        latitude: 0,
        longitude: 0,
        country: "US",
        smartyAddress: null,
      };

      setTimeout(function () {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          bundleParams: newAddress,
          smartyAddressBundle: iPlanFilterParams.smartyAddressInternet,
          groupByTerms: [],
          groupByProviders: [],
        });
      }, 100);

      dumSmarty = iPlanFilterParams.smartyAddressInternet;
      enterSearch = false;
    }

    if (dumSmarty) {
      if (
        addressValue !== "" &&
        addressValue !== iAuditVisitor.search.address
      ) {
        let type = EnumSearchType.HOME;

        if (window.location.pathname.includes("search")) {
          type = EnumSearchType.SEARCH;
        } else if (window.location.pathname.includes("multiple")) {
          type = EnumSearchType.MULTIPLE;
        }

        setIAuditVisitor({
          ...iAuditVisitor,
          search: {
            ...iAuditVisitor.search,
            id: -1,
            visitorId: iAuditVisitor.id,
            type: type,
            planTypeCode: iFilters.planTypeCode,
            address: `${dumSmarty.street_line}, ${
              dumSmarty.secondary !== ""
                ? `${dumSmarty.secondary}${dumSmarty.entries}, `
                : ""
            }${dumSmarty.city}, ${dumSmarty.state} ${dumSmarty.zipcode}`,
            stateAbbreviation: dumSmarty.state,
            city: dumSmarty.city,
            zipCode: dumSmarty.zipcode,
            seats: 0,
          },
        });
      }

      const addressDetail = `${dumSmarty.street_line}, ${
        dumSmarty.secondary !== ""
          ? `${dumSmarty.secondary}${dumSmarty.entries}, `
          : ""
      }${dumSmarty.city}, ${dumSmarty.state} ${dumSmarty.zipcode}`;
      const objDatLayer = {
        event: "search_bu",
        location: addressDetail,
      };
      dispatchDataLayer(objDatLayer);
    }

    if (!window.location.pathname.includes("search") && addressValue !== "") {
      if (smartyResponse && iPlanFilterParams.bundleParams.zipCode === "-1") {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          bundleParams: {
            ...iPlanFilterParams.bundleParams,
            zipCode: "",
          },
        });
      } else if (smartyResponse) {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          smartyAddressBundle: smartyResponse,
        });
      }

      setIsGoSearch(true);
    } else {
      if (enterSearch && dumSmarty) {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          smartyAddressBundle: dumSmarty,
          groupByTerms: [],
          groupByProviders: [],
        });
      }
    }
  };

  const onChangeAddress = (e: any) => {
    setAddressValue(e.currentTarget.value);
  };

  const onClickSelectAddressSuggestions = (
    smartyResponseDTO: ISmartyResponseDTO
  ) => {
    // Is home
    if (window.location.href === iPlanFilterParams.baseUrl) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        smartyAddressBundle: smartyResponseDTO,
        internetParams: {
          ...iPlanFilterParams.internetParams,
          address: "",
          city: "",
          state: "",
          streetLine: "",
          zipCode: "-1",
        },
      });
    } else if (!window.location.pathname.includes("search")) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        smartyAddressBundle: smartyResponseDTO,
      });
    }

    setSmartyResponse(smartyResponseDTO);
  };

  return (
    <div
      className={`tab-pane fade ${
        iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && "show active"
      }`}
      id="searchcontrol-bd"
      role="tabpanel"
      aria-labelledby="searchcontrol-bd-tab"
    >
      <div className="row">
        {/* <div className={`col-md-2 mt-2 ocultar-iconbundlemobile`}>
          <img
            src="/wp-content/wiresresources/images/v2/searchcontrol/computer-telephone-blue.svg"
            alt=""
          />
        </div>
        <div className={`col-md-2 mostrar-iconbundlemobile`}>
          <img
            src="/wp-content/wiresresources/images/v2/searchcontrol/computer-telephone-blue-mobile.svg"
            alt=""
          />
        </div> */}
        <div className="col-md-10 mt-2">
          <div className="input-group mb-2">
            <AddressText
              isMultipleAddress={false}
              isBillingAddress={false}
              placeHolder="Enter Address"
              onChangeAddressParent={onChangeAddress}
              onClickSelectAddressParent={onClickSelectAddressSuggestions}
              cssInputAddress="form-control input-address"
            />
          </div>
        </div>
        <div className="col-md-1 mt-2">
          <button
            type="button"
            className="btn btn-lg searchButton"
            onClick={onClickSearchBundle}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default TabBundle;
