import React, { useState } from "react";
import ClickToDialAgentAvailable from "../tbi/clickToDIal/clickToDialAgentAvailable";

interface IPlanAgentAvailableProps {}

const PlanAgentAvailable: React.FC<IPlanAgentAvailableProps> = () => {
  const [showFormToDial, setShowFormToDial] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const onClickToGetAdvice = (e: any) => {
    setShowFormToDial(!showFormToDial);
  };

  const onShowFormToDial = (showForm: boolean, showThanks: boolean) => {
    setShowFormToDial(showForm);
    setShowThankYou(showThanks);
  };

  const onClickChatNow = (e: any) => {
    if ((window as any).tidioChatApi) {
      (window as any).tidioChatApi.setVisitorData({
        distinct_id: 0,
        email: "",
        name: "",
        phone: "",
      });

      (window as any).tidioChatApi.setContactProperties({
        company_name: "",
        order_number: "",
        order_url: "",
      });

      (window as any).tidioChatApi.show();
      (window as any).tidioChatApi.open();
    }
  };

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-5dd8634 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="5dd8634"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5e86d6c"
          data-id="5e86d6c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-d54e8bd elementor-widget elementor-widget-html"
              data-id="d54e8bd"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div className="elementor-widget-container">
                <div className="container">
                  <div className="row agent-colorcontainer p-5 shadow">
                    <div className="col-md-4">
                      {!showFormToDial && (
                        <div className="agent-avaibleimage" />
                      )}
                    </div>
                    <div className="col-md-8">
                      <div className="col-md-12">
                        <span className="agent-text">
                          One of our Telecom Advisors
                          <br />
                          is available to help you find the
                          <br />
                          best deal for your business
                        </span>
                        <br />
                        <br />
                      </div>
                      <div className="col-md-12">
                        {showThankYou ? (
                          <span className="agent-available-thankyou">
                            THANK YOU
                          </span>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary agent-button"
                              onClick={onClickToGetAdvice}
                            >
                              CALL TO GET ADVICE
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary agent-button m-2"
                              onClick={onClickChatNow}
                            >
                              CHAT NOW
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {showFormToDial && (
                    <ClickToDialAgentAvailable
                      onShowFormToDial={onShowFormToDial}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanAgentAvailable;
