import React, { useEffect, useState } from 'react';
import { IRequestClickToDialTBIDTO } from '../../../contracts/types';
import useClickToDialSendOrder from '../../../hooks/api/useClickToDialSendOrder';
import useClickToDialSendTBI from '../../../hooks/api/useClickToDialSendTBI';
import NumberFormat from 'react-number-format';
import { useGlobalState } from '../../../state';

interface IClickToDialAgentAvailableProps {
    onShowFormToDial: (show: boolean, showThanks: boolean) => void;
}

const ClickToDialAgentAvailable: React.FC<IClickToDialAgentAvailableProps> = ({ onShowFormToDial }) => {
    const [iAuditVisitor] = useGlobalState('auditVisitor');

    const [requestSendOrder, setRequestSendOrder] = useState<IRequestClickToDialTBIDTO>();
    const [txtFirstName, setTxtFirstName] = useState('');
    const [txtPhone, setTxtPhone] = useState('');

    const { data: dataOrder } = useClickToDialSendOrder(requestSendOrder);

    const { data: dataTBI } = useClickToDialSendTBI(requestSendOrder);

    useEffect(() => {
        if (dataOrder && dataTBI) {
            onShowFormToDial(false, true);
        }
    }, [dataOrder, dataTBI])


    const onChangeFirstName = (e: any) => {
        setTxtFirstName(e.currentTarget.value);
    };

    const onChangePhone = (e: any) => {
        setTxtPhone(e.currentTarget.value);
    };

    const onClickSubmitTBI = (event: any) => {
        const request: IRequestClickToDialTBIDTO = {
            firstName: txtFirstName,
            lastName: txtFirstName,
            phone: txtPhone,
            email: '',
            queryString: window.location.search,
            referred: iAuditVisitor.referred
        };

        setRequestSendOrder(request);
    };

    return (
        <div className='row'>
            <div className="col-md-12 search-requestcall-footer" id="requestcall-footer">
                <form className="frmClickToDial" role="form" name="" action="" method="POST">
                    <div className="input-holder">
                        <label className="text-learnmore-footer" htmlFor="first_name">Name</label>
                        <div className="">
                            <input className="form-control input-learnfooter" type="text" name="first_name" id="first_name" value={txtFirstName} onChange={onChangeFirstName} autoComplete="off" />
                        </div>
                    </div>
                    <div className="input-holder">
                        <label className="text-learnmore-footer" htmlFor="number1">Phone Number</label>
                        <div className="">
                            <NumberFormat format="+1 (###) ###-####" mask="_" className={`form-control input-learnfooter`} value={txtPhone} onChange={onChangePhone} title="" required={true} id="number1" name="number1" autoComplete="off" />
                        </div>
                    </div>
                    <div className="divButtonSubmitClickToDial p-3">
                        <a className="btn btn-submit-agentavailable buttonSubmitClickToDial" href="javascript:void(0)" onClick={onClickSubmitTBI}>
                            Submit
                        </a>
                        <a className="btn btn-submit-agentavailable float-end buttonSubmitClickToDial" href="javascript:void(0)" onClick={() => onShowFormToDial(false, false)}>
                            Close
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ClickToDialAgentAvailable;
