import React, { useEffect, useState } from "react";

import {
  IResponseBundlePlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import equipment from "../../../images/equipment.png"; // with import
import techSupport from "../../../images/tech-support.svg"; // with import
import professionalInstall from "../../../images/professional_install.png"; // with import

interface IBundlePlanLargeCollapsedProps {
  plan: IResponseBundlePlansDTO;
  showMoreDetails: boolean;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const BundlePlanLargeCollapsed: React.FC<IBundlePlanLargeCollapsedProps> = ({
  plan,
  showMoreDetails,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iGlobals] = useGlobalState("globals");

  const [seeMoreSalesDescription, setSeeMoreSalesDescription] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreSalesDescription = () => {
    setSeeMoreSalesDescription(!seeMoreSalesDescription);
  };

  return (
    <div className="row">
      <div className="col-md-3 bundle-card-first">
        <button
          type="button"
          className={`btn internet-order-btn ${
            isCompare ? "metrics-SearchComparePlan" : "metrics-SearchRequestNow"
          }`}
          onClick={onClickOrderNow}
          data-name={plan.planName}
          data-Price={plan.planPrice.toFixed(2)}
          data-speed={plan.planDownloadSpeed}
          data-linePhone={
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.PHONE_LINE
            )?.value
          }
          data-term={plan.planTerm}
        >
          {isCompare ? "ADD TO COMPARE" : "Order"}
        </button>
      </div>
      <div className="col-md-2 internet-card-first">
        <div className="text-head">Price</div>
        <div>${plan.planPrice.toFixed(2)} / mo.</div>
      </div>
      <div className="col-md-2 internet-card-first">
        <div className="text-head">Term</div>
        <div>{plan.planTerm} Months</div>
      </div>
      <div className="col-md-3 internet-card-first">
        <div className="text-head">Internet Speed</div>
        <div>
          {plan.planDownloadSpeed >= 1000
            ? (plan.planDownloadSpeed / 1000).toFixed(0)
            : plan.planDownloadSpeed}

          {plan.planDownloadSpeed >= 1000 ? "Gbps" : "Mbps"}
        </div>
      </div>
      <div className="col-md-2 internet-card-first no-border">
        <div className="text-head">Phone Line</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.PHONE_LINE
            )?.value
          }
        </div>
      </div>
      {/* <div className="col-md-12 card-float-int">
        <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">SALES DESCRIPTION</th>
            </tr>
          </thead>
          <tbody>
            <tr className="align-middle">
              <td>
                <div>
                </div>
              </td>
              <td>
                <div>
                  <span id="card-price" className="card-price">
                    Price
                  </span>
                  <span className="voice-bolt">
                    ${plan.planPrice.toFixed(2)} / mon.
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <div className="card-price">Term</div>
                  <span className="card-max-term card-font-term">
                    
                  </span>
                  <div className="voice-bolt">{plan.planTerm} Months</div>
                </div>
              </td>
              <td>
                <div>
                  <div id="card-price" className="card-price">
                    Internet Speed
                  </div>
                  <div id="" className="voice-bolt">
                    <span className="card-icon-bolt-voice"></span>
                    {plan.planDownloadSpeed >= 1000
                      ? (plan.planDownloadSpeed / 1000).toFixed(0)
                      : plan.planDownloadSpeed}

                    {plan.planDownloadSpeed >= 1000 ? "Gbps" : "Mbps"}
                    <span
                      className={`${
                        plan.planDownloadSpeed >= 1000
                          ? "card-icon-gbps-voice bundle-speed"
                          : "card-icon-mbps-voice bundle-speed"
                      }`}
                    ></span>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div className="card-price">Phone Line</div>
                  <span id="" className="voice-bolt">
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PHONE_LINE
                      )?.value
                    }
                  </span>
                </div>
              </td>
              <td>
                <div
                  className={
                    seeMoreSalesDescription
                      ? "card-recommended-scroll"
                      : "card-recommended"
                  }
                >
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.SALES_DESCRIPTION
                    )?.value
                  }
                </div>
                <div
                  className="card-view-call link-url metrics-SearchSeeMore"
                  onClick={onClickSeeMoreSalesDescription}
                  data-name={plan.planName}
                  data-Price={plan.planPrice.toFixed(2)}
                  data-speed={plan.planDownloadSpeed}
                  data-linePhone={
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.PHONE_LINE
                    )?.value
                  }
                  data-term={plan.planTerm}
                >
                  {seeMoreSalesDescription ? "See less" : "See more"}
                  <br />
                  <i
                    className={
                      seeMoreSalesDescription
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div className="row">
        <table className="table bundle-card-table-second">
          <tbody className="card-more-table">
            <tr>
              <td>
                <img src={professionalInstall} alt="" />
              </td>
              <td>
                <img src={equipment} alt="" />
              </td>
              {/* <td>
                <img src={techSupport} alt="" />
              </td> */}
              {/* <th>
                <div className="card-icon-carrier mx-auto d-block" />
              </th> */}
            </tr>
            <tr>
              <td>Professional Installation</td>
              <td>Equipment Fee</td>
              {/* <td>Tech Support</td> */}
              {/* <td>
                <div className="card-view-title"></div>
              </td>
              <td>
                <span className="card-view-call">
                  See even more details{" "}
                  <i
                    className="fa fa-chevron-right align-middle"
                    aria-hidden="true"
                  />
                </span>
              </td> */}
            </tr>
            <tr>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.INSTALLATION
                    )?.value
                  }
                </div>
                {/* <div
                  className="card-view-call link-url"
                  onClick={onClickSeeMoreInstallation}
                >
                  {seeMoreInstallation ? "See less" : "See more"}
                  <br />
                  <i
                    className={
                      seeMoreInstallation
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div> */}
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.EQUIPMENT_FEE
                    )?.value
                  }
                </div>
              </td>
              {/* <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECH_SUPPORT
                    )?.value
                  }
                </div>
                <div
                  className="card-view-call link-url"
                  onClick={onClickSeeMoreTechSupport}
                >
                  {seeMoreTechSupport ? "See less" : "See more"}
                  <br />
                  <i
                    className={
                      seeMoreTechSupport
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div>
              </td> */}
              {/* <td>
                
                <div
                  className="card-view-call link-url"
                  onClick={onClickSeeMoreWhyThisCarrier}
                >
                  {seeMoreWhyThisCarrier ? "See less" : "See more"}
                  <br />
                  <i
                    className={
                      seeMoreWhyThisCarrier
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div>
              </td>
              <td></td> */}
            </tr>
          </tbody>
        </table>
      </div>
      {!showMoreDetails && (
        <>
          {/* <div className="col-md-12">
            <table className="table card-table">
              <thead>
                <tr className="align-middle card-font-weight">
                  <th scope="col">Professional installation</th>
                  <th scope="col">Equipment fee</th>
                  <th scope="col">Tech support</th>
                  <th scope="col">Sales description</th>
                </tr>
              </thead>
            </table>
          </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-3"></div>
              <div className="col-sm-3">
                {/* <div className="card-center-call">
                  <span
                    className="card-view-call metrics-SearchCall"
                    data-name={plan.planName}
                    data-Price={plan.planPrice.toFixed(2)}
                    data-speed={plan.planDownloadSpeed}
                    data-linePhone={
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PHONE_LINE
                      )?.value
                    }
                    data-term={plan.planTerm}
                  >
                    <p>
                      Call to Get Advice <b>1.844.947.7737</b>
                    </p>
                  </span>
                </div> */}
              </div>
              <div className="col-sm-3 view-more-details">
                <span
                  className="card-view-call link-url metrics-SearchViewDetail"
                  onClick={onClickShowMoreDetails}
                  data-name={plan.planName}
                  data-Price={plan.planPrice.toFixed(2)}
                  data-speed={plan.planDownloadSpeed}
                  data-linePhone={
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.PHONE_LINE
                    )?.value
                  }
                  data-term={plan.planTerm}
                >
                  View more details
                  <i
                    className="fa fa-chevron-down view-more-icon"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BundlePlanLargeCollapsed;
