import React from "react";

import {
  EnumPropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";

interface IVoicePlanLargeMobileExpandedProps {
  plan: IResponseVoicePlansDTO;
}

const VoicePlanLargeMobileExpanded: React.FC<
  IVoicePlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iGlobals] = useGlobalState("globals");

  return (
    <>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-support mx-auto d-block" />
        <div className="card-view-title">TECH SUPPORT</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.TECH_SUPPORT
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-check-technology mx-auto d-block" />
        <div className="card-view-title">TECHNOLOGY</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.TECHNOLOGY
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-technology mx-auto d-block" />
        <div className="card-view-title">LONG DISTANCE</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.LONG_DISTANCE
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-sla mx-auto d-block" />
        <div className="card-view-title">SLA</div>
        <div>
          {iGlobals.productProperties.find(
            (pp) =>
              pp.productId === plan.productId &&
              pp.propertyCode === EnumPropertyId.SLA
          )?.value || "No"}
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-phone mx-auto d-block" />
        <div className="card-view-title">PHONE INCLUDED</div>
        <div>
          {iGlobals.productProperties.find(
            (pp) =>
              pp.productId === plan.productId &&
              pp.propertyCode === EnumPropertyId.EQUIPMENT_INCLUDED
          )?.value || "No"}
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 card-tr-title p-2 mobile-espace">
        WHY THIS CARRIER
      </div>
      <div className="col-12 text-center p-2">
        <div className="card-recommended-scroll">
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
            )?.value
          }
        </div>
        {/* <div className="card-view-call" onClick={onClickSeeMoreWhyCarrier}>
          See more <br />
          <i
            className={
              seeMoreWhyCarrier ? "fa fa-chevron-up" : "fa fa-chevron-down"
            }
            aria-hidden="true"
          />
        </div> */}
      </div>
    </>
  );
};

export default VoicePlanLargeMobileExpanded;
