import React, { useEffect, useState } from "react";

import {
  IResponseInternetPlansDTO,
  IPlansCompare,
  IAuditVisitorSearchCompare,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import InternetPlanLargeCollapsed from "./internetPlanLargeCollapsed.comp";
import InternetPlanLargeExpanded from "./internetPlanLargeExpanded.comp";
import InternetPlanLargeMobile from "./internetPlanLargeMobile.comp";
import InternetPlanLargeMobileCollapsed from "./internetPlanLargeMobileCollapsed.comp";
import InternetPlanLargeMobileExpanded from "./internetPlanLargeMobileExpanded.comp";
import InternetPlanLargeMobileFoot from "./internetPlanLargeMobileFoot.comp";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IInternetPlanLargeProps {
  plan: IResponseInternetPlansDTO;
  lastElementRef?: any;
  isCompare?: boolean;
}

const InternetPlanLarge: React.FC<IInternetPlanLargeProps> = ({
  plan,
  lastElementRef,
  isCompare = false,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const {} = useAuditSaveCompare();

  const onChangeCompareCheckBox = (
    planId: number,
    codeProvider: string | undefined
  ) => {
    let plansCompare: IPlansCompare[] =
      iFilters.plansCompareInternet == null
        ? []
        : iFilters.plansCompareInternet;
    const exists = plansCompare.find(
      (p) => p.idPlan === planId && p.codeProvider === codeProvider
    );

    if (exists) {
      plansCompare = plansCompare.filter((pc) => {
        if (pc.codeProvider === codeProvider && pc.idPlan === planId) {
          return 0;
        }

        return 1;
      });
    } else {
      const speed =
        plan.planUploadSpeed < 1000
          ? `${plan.planUploadSpeed}Mbps`
          : `${plan.planUploadSpeed / 1000}Gbps`;
      const planComp: IPlansCompare = {
        idPlan: planId,
        codeProvider: codeProvider,
        planName: `${plan.providerName} ${speed} ${plan.planTechnologyName} ${plan.planTerm}mo`,
        planPrice: plan.planPrice,
      };

      plansCompare.push(planComp);
    }

    setIFilters({
      ...iFilters,
      plansCompareInternet: plansCompare,
    });

    if (!exists) {
      const searchCompare: IAuditVisitorSearchCompare = {
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        planId: plan.planId,
        internalPlanId: plan.internalPlanId,
        providerId: plan.providerId,
        learnMore: 0,
        remove: false,
        codeProvider: plan.codeProvider ? plan.codeProvider : "",
      };

      setIAuditVisitor({
        ...iAuditVisitor,
        searchCompare: searchCompare,
      });
    } else {
      let searchCompare = iAuditVisitor.search.compares.find(
        (sc) =>
          sc.planId === plan.planId && sc.codeProvider === plan.codeProvider
      );

      if (searchCompare) {
        searchCompare.remove = true;

        setIAuditVisitor({
          ...iAuditVisitor,
          searchCompare: searchCompare,
        });
      }
    }
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  const onClickOrderNow = () => {
    if (isCompare) {
      onChangeCompareCheckBox(plan.planId, plan.codeProvider);
      return;
    }

    let surveyData = iPlanFilterParams.surveyData;
    surveyData.planId = plan.planId;
    surveyData.providerId = plan.providerId;
    surveyData.internalPlanId = plan.internalPlanId;

    if (
      iPlanFilterParams.idAddressMultiple === -1 ||
      iPlanFilterParams.multipleAddresses.multipleInternetParams.length === 1
    ) {
      surveyData.isOpen = true;
      surveyData.orderId = -1;
      surveyData.isPageThanks = false;

      setIPlanFilterParams({
        ...iPlanFilterParams,
        internetParams: {
          ...iPlanFilterParams.internetParams,
          plan: plan,
        },
        surveyData: surveyData,
      });

      setTimeout(function () {
        window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
      }, 100);
    } else {
      const arrayMultiple =
        iPlanFilterParams.multipleAddresses.multipleInternetParams;

      let paramsInternetSelect = arrayMultiple.find(
        (paramsInternet) =>
          paramsInternet.idMultiple === iPlanFilterParams.idAddressMultiple
      );

      if (paramsInternetSelect) {
        paramsInternetSelect.plan = plan;
      }

      setIPlanFilterParams({
        ...iPlanFilterParams,
        multipleAddresses: {
          ...iPlanFilterParams.multipleAddresses,
          multipleInternetParams: arrayMultiple,
        },
      });

      setTimeout(function () {
        window.open(iPlanFilterParams.baseUrl + "multiple", "_self");
      }, 100);
    }
  };

  const onClickChooseThisVariousAddress = () => {
    iPlanFilterParams.multipleAddresses.multipleInternetParams.forEach(
      (addressParam) => {
        // TODO multiple
        // addressParam.multiplePlans.forEach((multiPlan) => {
        //     if (multiPlan.planId === plan.planId) {
        //         addressParam.plan = plan;
        //     }
        // });
      }
    );

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams:
          iPlanFilterParams.multipleAddresses.multipleInternetParams,
      },
    });

    if (!iPlanFilterParams.isLocalhost) {
      window.open(iPlanFilterParams.baseUrl + "multiple", "_self");
    }
  };

  const onClickShowMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  useEffect(() => {
    // TODO multiple
    // const countAddress = iPlanFilterParams.multipleAddresses.multipleInternetParams.filter((addressParam) => {
    //     const count = addressParam.multiplePlans.filter((multiPlan) => multiPlan.planId === plan.planId).length;
    //     if (count > 0) {
    //         return addressParam;
    //     }
    // }).length;
    // if (iPlanFilterParams.multipleAddresses.multipleInternetParams.length === countAddress) {
    //     setSelectThisForAddress('Select this plan for all my addresses');
    // } else {
    //     setSelectThisForAddress(`Select this plan for ${countAddress} addresses`);
    // }
  }, []);

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-3364ac2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="3364ac2"
      data-element_type="section"
      ref={lastElementRef}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-00a0bc9"
          data-id="00a0bc9"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-a493899 elementor-widget elementor-widget-html"
              data-id="a493899"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div className="elementor-widget-container">
                <div className="container card-container ocultar-card-deskop">
                  <div className="row">
                    <div className="col-md-4">
                      <div className={showMoreDetails ? "card-view-space" : ""}>
                        {plan.providerLogo === "logoWiresurferDummy.png" ? (
                          <img
                            className={
                              !showMoreDetails
                                ? "card-space-img"
                                : "mx-auto d-block"
                            }
                            src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
                            alt=""
                          />
                        ) : (
                          <img
                            className={
                              !showMoreDetails
                                ? "card-space-img"
                                : "mx-auto d-block"
                            }
                            src={`data:image/jpeg;base64,${
                              iGlobals.carriers.find(
                                (c) => c.id === plan.providerId
                              )?.imageBase64
                            }`}
                            alt=""
                          />
                        )}
                        {/* <div className="text-center">
                          <div
                            className={`${
                              iFilters.plansCompareInternet.find(
                                (p) => p.idPlan === plan.planId
                              )
                                ? "circle-check-on"
                                : "circle-check"
                            } metrics-SearchComparePlan`}
                            onClick={() =>
                              onChangeCompareCheckBox(
                                plan.planId,
                                plan.codeProvider
                              )
                            }
                            data-name={plan.planName}
                            data-Price={plan.planPrice.toFixed(2)}
                            data-speed={plan.planDownloadSpeed}
                            data-linePhone="N/A"
                            data-term={plan.planTerm}
                          />
                          {iFilters.plansCompareInternet &&
                          iFilters.plansCompareInternet.length >= 1 ? (
                            <span
                              className="card-compareplan link-url"
                              onClick={onClickCompareLink}
                            >
                              Compare Plan
                            </span>
                          ) : (
                            <span className="card-compareplan">
                              Compare Plan
                            </span>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-8 card-float-int service-type-name">
                      {plan.planName}
                    </div>
                  </div>
                  <InternetPlanLargeCollapsed
                    plan={plan}
                    showMoreDetails={showMoreDetails}
                    onClickShowMoreDetails={onClickShowMoreDetails}
                    onClickOrderNow={onClickOrderNow}
                    isCompare={isCompare}
                  />

                  {showMoreDetails && (
                    <InternetPlanLargeExpanded
                      plan={plan}
                      onClickShowMoreDetails={onClickShowMoreDetails}
                      onClickOrderNow={onClickOrderNow}
                      isCompare={isCompare}
                    />
                  )}
                </div>
                <div className="container card-container ocultar-card-mobile">
                  <br />
                  <div className="p-4">
                    <InternetPlanLargeMobile
                      plan={plan}
                      onChangeCompareCheckBox={onChangeCompareCheckBox}
                    />
                    <div className="row text-center">
                      {!showMoreDetails ? (
                        <InternetPlanLargeMobileCollapsed />
                      ) : (
                        <InternetPlanLargeMobileExpanded plan={plan} />
                      )}
                      <InternetPlanLargeMobileFoot
                        plan={plan}
                        showMoreDetails={showMoreDetails}
                        onClickShowMoreDetails={onClickShowMoreDetails}
                        onClickOrderNow={onClickOrderNow}
                        isCompare={isCompare}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InternetPlanLarge;
