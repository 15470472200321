import React, { useEffect, useState } from "react";
import {
  EnumSortBy,
  IPlanFilterParamsInternet,
  IResponseInternetPlansDTO,
  IWrSettings,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import AddressText from "../../search/addressText.comp";
import BulkEditSection from "../bulkEditSection.comp";
import GroupAddressItem from "./groupAddressItem.comp";
import GroupPlanItem from "./groupPlanItem.comp";

interface IGroupAddressProps {
  wrSettings: IWrSettings;
}

const GroupAddress: React.FC<IGroupAddressProps> = ({ wrSettings }) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const arraMultipleInternetParams =
    iPlanFilterParams.multipleAddresses?.multipleInternetParams;

  useEffect(() => {
    if (
      iPlanFilterParams.removeQuery &&
      iPlanFilterParams.removeQuery === true
    ) {
      iPlanFilterParams.multipleAddresses?.multipleInternetParams.forEach(
        (internetParams) => {
          setTimeout(function () {
            setIPlanFilterParams({
              ...iPlanFilterParams,
              internetParams: internetParams,
              removeQuery: false,
            });
          }, 100);
        }
      );
    }
  }, []);

  const onClickOrderNow = () => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      surveyData: {
        ...iPlanFilterParams.surveyData,
        isOpen: true,
        orderId: -1,
        isPageThanks: false,
      },
    });

    if (!iPlanFilterParams.isLocalhost) {
      window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
    }
  };

  const onChangeTotalPrice = (price: number) => {
    setTotalPrice(price);
  };

  const getPlanWireSurfer = (numberProviders: number) => {
    let note =
      "Want additional options? A Wiresurfer telecom expert is standing by to assist from the over 220+ telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";

    if (numberProviders === 0) {
      note =
        "A Wiresurfer telecom expert is standing by to assist from the over 220+ business telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";

      if (iFilters.showMoreLocations) {
        note =
          "A Wiresurfer telecom expert is standing by to assist for these locations from the over 220+ telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";
      }
    }

    const planWire: IResponseInternetPlansDTO = {
      planId: -1,
      productId: -1,
      internalPlanId: -1,
      planDownloadSpeed: 0,
      planFiberFastQualType: "",
      planInstallPrice: 0,
      planModemIncluded: false,
      planName: "wiresurfer",
      planNote: note,
      planPrice: 0,
      planRated: 0,
      planServiceTypeCode: "",
      planTechnologyCode: undefined,
      planTechnologyName: "",
      planTerm: 0,
      planTypeCode: undefined,
      planTypeName: "Wiresurfer Agent Available",
      planUploadSpeed: 0,
      providerId: -1,
      providerLogo: "logoWiresurferDummy.png",
      providerName: "Wiresurfer",
      planLatitude: 0,
      planLongitude: 0,
    };

    return planWire;
  };

  const onClickSelectAddAddressParent = (
    pInternetParams: IPlanFilterParamsInternet
  ) => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      internetParams: pInternetParams,
    });
  };

  return (
    <>
      <BulkEditSection wrSettings={wrSettings} />
      {
        <div className="divGroupAddress">
          <table>
            <thead>
              <tr>
                <th>Address (group)</th>
                <th>Carrrier</th>
                <th>Speed</th>
                <th>Term</th>
                <th>Price</th>
                <th></th>
                <th>Compare offers</th>
              </tr>
            </thead>
            <tbody>
              {iPlanFilterParams.multipleAddresses?.multipleInternetParams
                .sort((a, b) => {
                  if (a.idMultiple > b.idMultiple) {
                    return 1;
                  }
                  return -1;
                })
                .map((internetParams, index) => {
                  let arrayPlans: IResponseInternetPlansDTO[] = [];
                  internetParams.plansByCodeProvider.forEach((pc) => {
                    arrayPlans = arrayPlans.concat(pc.plans);
                  });

                  let multiplePlans = arrayPlans.filter((p) => {
                    if (iFilters.filterTerm.includes(p.planTerm)) {
                      return 1;
                    }
                    return -1;
                  });

                  return (
                    <>
                      <GroupAddressItem
                        index={index}
                        internetParams={internetParams}
                      />
                      {internetParams.openedGroupAddressPlans &&
                        multiplePlans
                          .filter((p) => !p.planHidden && p.planId !== -1)
                          .sort((a, b) => {
                            switch (iFilters.sortBy) {
                              case EnumSortBy.LOW_TO_HIGH_PRICE:
                                return a.planPrice - b.planPrice !== 0
                                  ? a.planPrice - b.planPrice
                                  : a.planTerm - b.planTerm;
                              case EnumSortBy.HIGH_TO_LOW_PRICE:
                                return b.planPrice - a.planPrice !== 0
                                  ? b.planPrice - a.planPrice
                                  : a.planTerm - b.planTerm;
                              case EnumSortBy.LOW_TO_HIGH_SPEED:
                                return (
                                  a.planDownloadSpeed - b.planDownloadSpeed
                                );
                              case EnumSortBy.HIGH_TO_LOW_SPEED:
                                return (
                                  b.planDownloadSpeed - a.planDownloadSpeed
                                );
                              default:
                                return b.planRated - a.planRated;
                            }
                          })
                          .slice(
                            0,
                            iFilters.filterOnlyBestPerAddress
                              ? iPlanFilterParams.numberBestPlansPerAddress
                              : multiplePlans.filter((p) => !p.planHidden)
                                  .length
                          )
                          .map((plan) => {
                            const providerLogo = iGlobals.carriers.find(
                              (c) => c.id === plan.providerId
                            );
                            plan.providerLogo = providerLogo
                              ? providerLogo.imageBase64
                              : plan.providerLogo;

                            return (
                              <GroupPlanItem
                                idMultiple={internetParams.idMultiple}
                                plan={plan}
                                internetParams={internetParams}
                                onChangeTotalPrice={onChangeTotalPrice}
                              />
                            );
                          })}
                      {internetParams.openedGroupAddressPlans && (
                        <GroupPlanItem
                          idMultiple={internetParams.idMultiple}
                          plan={getPlanWireSurfer(multiplePlans.length)}
                          internetParams={internetParams}
                          onChangeTotalPrice={onChangeTotalPrice}
                        />
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
          {/* <div className="row">
                        <div className="col-md-12">
                            <div className="row fixItRowSelect divInputAddAddress">
                                <AddressText isMultipleAddress={true} isBillingAddress={false} placeHolder="Enter Address" onClickSelectAddAddressParent={onClickSelectAddAddressParent} cssInputAddress="inputAddAddress" />
                            </div>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-md-3">
              <table className="tablePriceGroup">
                <tbody>
                  <tr>
                    <th>Total:</th>
                    <td>
                      <span>$</span> {totalPrice.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3">
              {arraMultipleInternetParams.length >= 1 && (
                <button
                  className="buttonOrderGroupAddress"
                  onClick={onClickOrderNow}
                >
                  Order Now
                </button>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default GroupAddress;
