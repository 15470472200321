import React, { useEffect, useState } from "react";

import {
  IResponseBundlePlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";

interface IBundlePlanLargeExpandedProps {
  plan: IResponseBundlePlansDTO;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const BundlePlanLargeExpanded: React.FC<IBundlePlanLargeExpandedProps> = ({
  plan,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iGlobals] = useGlobalState("globals");

  const [seeMoreTechSupport, setSeeMoreTechSupport] = useState(false);
  const [seeMoreWhyThisCarrier, setSeeMoreWhyThisCarrier] = useState(false);
  const [seeMoreInstallation, setSeeMoreInstallation] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreTechSupport = () => {
    setSeeMoreTechSupport(!seeMoreTechSupport);
  };

  const onClickSeeMoreWhyThisCarrier = () => {
    setSeeMoreWhyThisCarrier(!seeMoreWhyThisCarrier);
  };

  const onClickSeeMoreInstallation = () => {
    setSeeMoreInstallation(!seeMoreInstallation);
  };

  return (
    <>
      <div className="row">
        <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th></th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="">
              <td>
                <div className="card-view-title more-card-view-title">
                  WHY THIS CARRIER
                </div>
              </td>
              {/* <td>
                <div className="card-view-title more-card-view-title">
                  SALES DESCRIPTION
                </div>
              </td> */}
            </tr>
            <tr className="">
              <td>
                <div className="card-recommended more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
              </td>
              {/* <td>
                <div className="card-recommended more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.SALES_DESCRIPTION
                    )?.value
                  }
                </div>
              </td> */}
            </tr>
            <tr className=""></tr>
            <tr className=""></tr>
          </tbody>
        </table>
      </div>

      <div>
        <div className="col-md-12">
          <div className="row card-text-center">
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-2">
              {/* <div className="card-center-call">
                                <span className="card-view-call">Call to Get Advice <b>1.844.947.7737</b>
                                </span>
                            </div> */}
            </div>
            <div className="col-md-4 view-more-details">
              <span
                className="card-view-call link-url"
                onClick={onClickShowMoreDetails}
              >
                View less details
                <i
                  className="fa fa-chevron-up view-more-icon"
                  aria-hidden="true"
                />
              </span>
              {/* <button
                                type="button"
                                className={`btn card-button-quote ${!isCompare && 'metrics-SearchRequestNow'}`}
                                onClick={onClickOrderNow}
                                data-name={plan.planName}
                                data-Price={plan.planPrice.toFixed(2)}
                                data-speed={plan.planDownloadSpeed}
                                data-linePhone={iGlobals.productProperties.find((pp) => pp.productId === plan.productId && pp.propertyCode === EnumPropertyId.PHONE_LINE)?.value}
                                data-term={plan.planTerm}
                            >
                                {isCompare ? 'ADD TO COMPARE' : 'Order Now'}
                            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BundlePlanLargeExpanded;
