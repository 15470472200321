import React, { useState, useEffect } from "react";
import { EnumPlanType } from "../../contracts/types";
import { useGlobalState } from "../../state";
import TabInternet from "./tabInternet.comp";
import TabVoice from "./tabVoice.comp";
import TabBundle from "./tabBundle.comp";
import useGetParameters from "../../hooks/api/useGetParameters";

interface ITabProps {
  isPageCompare: boolean;
}

const Tab: React.FC<ITabProps> = ({ isPageCompare }) => {
  const [iFilters, setIFilters] = useGlobalState("filters");

  const {} = useGetParameters();

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("business-phone")) {
      setIFilters({
        ...iFilters,
        planTypeCode: EnumPlanType.BUSINESS_VOICE_PHONE,
      });
    }
  }, []);

  const onClickTab = (e: any, planTypeCodeParam: EnumPlanType) => {
    if (planTypeCodeParam === iFilters.planTypeCode) {
      return;
    }

    setIFilters({ ...iFilters, planTypeCode: planTypeCodeParam });
  };

  return (
    <div className="container">
      <div className="p-4 shadow bg-body search-container">
        <div
          className="nav nav-pills nav-justified flex-column flex-md-row mb-3"
          id="searchcontrol-tab"
          role="tablist"
        >
          {!isPageCompare && (
            <>
              <button
                className={`nav-link search-tab ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
                  "active"
                }`}
                id="searchcontrol-bi-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bi"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bi"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_INTERNET)
                }
              >
                <div className="iconbi align-middle"></div>Business Internet
              </button>
              <button
                className={`nav-link search-tab mx-md-3 mx-md-3 my-2 my-md-0 ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE &&
                  "active"
                }`}
                id="searchcontrol-bp-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bp"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bp"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_VOICE_PHONE)
                }
              >
                <div className="iconbp align-middle"></div>VOIP & Phone
              </button>
              <button
                className={`nav-link search-tab ${
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES &&
                  "active"
                }`}
                id="searchcontrol-bd-tab"
                data-bs-toggle="pill"
                data-bs-target="#searchcontrol-bd"
                type="button"
                role="tab"
                aria-controls="searchcontrol-bd"
                aria-selected={
                  iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES
                    ? "true"
                    : "false"
                }
                onClick={(e: any) =>
                  onClickTab(e, EnumPlanType.BUSINESS_BUNDLES)
                }
              >
                <div className="iconbd align-middle"></div>Bundles
              </button>
            </>
          )}
        </div>
        <div className="tab-content" id="searchcontrol-tabContent">
          <TabInternet />
          <TabVoice />
          <TabBundle />
        </div>
      </div>
    </div>
  );
};

export default Tab;
