import React, { useEffect, useState } from "react";
import {
  EnumPropertyId,
  IResponseBundlePlansDTO,
} from "../../../contracts/types";
import useBundlePlans from "../../../hooks/api/useBundlePlans";
import { useGlobalState } from "../../../state";
import Loading from "../../loading.comp";
import ComparePrice from "./comparePrice.com";
import CompareProperty from "./compareProperty.com";
import CompareSpeed from "./compareSpeed.com";
import CompareTerm from "./compareTerm.com";
import CompareTitle from "./compareTitle.com";

interface ICompareBundleProps {}

const CompareBundle: React.FC<ICompareBundleProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");

  const [plansDataCompare, setPlansDataCompare] =
    useState<IResponseBundlePlansDTO[]>();

  const { data, isLoading } = useBundlePlans(1);

  useEffect(() => {
    if (data) {
      const arrayPC: IResponseBundlePlansDTO[] = [];

      iFilters.plansCompareBundle.forEach((planCompare) => {
        const plan = data.lstResponseBundlePlansDTO.find(
          (p) => p.planId === planCompare.idPlan
        );
        const planPC = arrayPC.find((p) => p.planId === plan?.planId);

        if (plan && !planPC) {
          arrayPC.push(plan);
        }
      });

      setPlansDataCompare(arrayPC);
    }
  }, [data, iFilters.plansCompareBundle.length]);

  const onClickOrderNow = (plan: IResponseBundlePlansDTO) => {
    if (plan) {
      let surveyData = iPlanFilterParams.surveyData;
      surveyData.planId = plan.planId;
      surveyData.providerId = plan.providerId;
      surveyData.internalPlanId = plan.internalPlanId;

      surveyData.isOpen = true;
      surveyData.orderId = -1;
      surveyData.isPageThanks = false;

      setIPlanFilterParams({
        ...iPlanFilterParams,
        bundleParams: {
          ...iPlanFilterParams.bundleParams,
          plan: plan,
        },
        surveyData: surveyData,
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading typeName="Bundles providers nationwide!" />
      ) : (
        plansDataCompare && (
          <table className="table table-Compare align-middle">
            <tbody>
              <CompareTitle plansDataCompare={plansDataCompare} />
              <tr>
                <th className="headcol-compare label-compareBi">
                  {" "}
                  SPECIFICATIONS
                </th>
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <img
                      className="compareBI-logo"
                      src={`data:image/jpeg;base64,${
                        iGlobals.carriers.find((c) => c.id === plan.providerId)
                          ?.imageBase64
                      }`}
                      alt=""
                    />
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.planPrice.toFixed(2)}
                      data-speed={plan.planDownloadSpeed}
                      data-linePhone={
                        iGlobals.productProperties.find(
                          (pp) =>
                            pp.productId === plan.productId &&
                            pp.propertyCode === EnumPropertyId.PHONE_LINE
                        )?.value
                      }
                      data-term={plan.planTerm}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
              <CompareSpeed plansDataCompare={plansDataCompare} />
              <ComparePrice plansDataCompare={plansDataCompare} />
              <CompareProperty
                title="PHONE LINE"
                propertyCode={EnumPropertyId.PHONE_LINE}
                plansDataCompare={plansDataCompare}
              />
              <CompareTerm plansDataCompare={plansDataCompare} />
              <CompareProperty
                title="DEALS"
                propertyCode={EnumPropertyId.DEALS}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="WHY THIS CARRIER"
                propertyCode={EnumPropertyId.WHYTHISCARRIER}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="EQUIPMENT FEE"
                propertyCode={EnumPropertyId.EQUIPMENT_INCLUDED}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="PROFESSIONAL INSTALLATION"
                propertyCode={EnumPropertyId.INSTALLATION}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="TECH SUPPORT"
                propertyCode={EnumPropertyId.TECH_SUPPORT}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="DIFFERENTS ATTRIBUTES"
                propertyCode={EnumPropertyId.DIFERENTS_ATTRIBUTES}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="SECURITY"
                propertyCode={EnumPropertyId.SECURITY}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="AUTO PAY AND PAPERLESS BILLING DISCOUNT"
                propertyCode={EnumPropertyId.AUTO_PAY}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="GIFT"
                propertyCode={EnumPropertyId.GIFT}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="KEY FEATURES"
                propertyCode={EnumPropertyId.KEY_FEATURES}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="UNLIMITED INTERNET DATA/CALLS"
                propertyCode={EnumPropertyId.UNLIMITED_INTERNET}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="ADDITIONAL FEATURES"
                propertyCode={EnumPropertyId.ADDITIONAL_FEATURES}
                plansDataCompare={plansDataCompare}
              />
              <tr>
                <th className="headcol-compare" />
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.planPrice.toFixed(2)}
                      data-speed={plan.planDownloadSpeed}
                      data-linePhone={
                        iGlobals.productProperties.find(
                          (pp) =>
                            pp.productId === plan.productId &&
                            pp.propertyCode === EnumPropertyId.PHONE_LINE
                        )?.value
                      }
                      data-term={plan.planTerm}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )
      )}
    </>
  );
};

export default CompareBundle;
