import React, { useEffect, useState } from "react";
import {
  EnumPropertyId,
  EnumViewPlansType,
  IOrderSuProductDTO,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import Loading from "../../loading.comp";
import ComparePrice from "./comparePrice.com";
import CompareProperty from "./compareProperty.com";
import CompareTerm from "./compareTerm.com";
import CompareTitle from "./compareTitle.com";
import useVoicePlans from "../../../hooks/api/useVoicePlans";

interface ICompareVoiceProps {}

const CompareVoice: React.FC<ICompareVoiceProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");

  const [plansDataCompare, setPlansDataCompare] =
    useState<IResponseVoicePlansDTO[]>();

  const { data, isLoading } = useVoicePlans(EnumViewPlansType.LARGE);

  useEffect(() => {
    if (data) {
      const arrayPC: IResponseVoicePlansDTO[] = [];

      iFilters.plansCompareVoice.forEach((planIdCompare) => {
        const plan = data.lstResponseVoicePlansDTO.find(
          (p) => p.planId === planIdCompare
        );
        if (plan) {
          arrayPC.push(plan);
        }
      });

      setPlansDataCompare(arrayPC);
    }
  }, [data, iFilters.plansCompareVoice.length]);

  const onClickOrderNow = (plan: IResponseVoicePlansDTO) => {
    if (plan) {
      let orderSubProductsPlan: IOrderSuProductDTO[] = [];

      iPlanFilterParams.voiceParams.filterConfigSubProducts.forEach(
        (subPro) => {
          if (subPro.filterConfig > 0) {
            const subProdPlan = subPro.plans.find(
              (sp) => sp.productId === plan.productId
            );
            let unitPrice = 0;

            if (subProdPlan && subProdPlan.values) {
              const subProPlanVal = subProdPlan.values
                .sort((a, b) => {
                  return a.quantity - b.quantity;
                })
                .find((s) => subPro.filterConfig >= s.quantity);
              unitPrice = subProPlanVal ? subProPlanVal.unitPrice : 0;
            }

            const orderSubProductDTO: IOrderSuProductDTO = {
              orderId: iPlanFilterParams.surveyData.orderId,
              subProductId: subPro.id,
              description: subPro.name,
              subProductNumber: subPro.filterConfig,
              unitPrice: unitPrice,
            };

            orderSubProductsPlan.push(orderSubProductDTO);
          }
        }
      );

      setIPlanFilterParams({
        ...iPlanFilterParams,
        voiceParams: {
          ...iPlanFilterParams.voiceParams,
          plan: plan,
        },
        surveyData: {
          ...iPlanFilterParams.surveyData,
          orderId: -1,
          isOpen: true,
          isPageThanks: false,
          planId: plan.planId,
        },
        subProductsPlan: orderSubProductsPlan,
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading typeName="Voice providers nationwide!" />
      ) : (
        plansDataCompare && (
          <table className="table table-Compare align-middle">
            <tbody>
              <CompareTitle plansDataCompare={plansDataCompare} />
              <tr>
                <th className="headcol-compare label-compareBi">
                  {" "}
                  SPECIFICATIONS
                </th>
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <img
                      className="compareBI-logo"
                      src={`data:image/jpeg;base64,${
                        iGlobals.carriers.find((c) => c.id === plan.providerId)
                          ?.imageBase64
                      }`}
                      alt=""
                    />
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.calculatedPrice.toFixed(2)}
                      data-speed="N/A"
                      data-linePhone="N/A"
                      data-term={plan.term}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
              <ComparePrice plansDataCompare={plansDataCompare} />
              <CompareTerm plansDataCompare={plansDataCompare} />
              <tr>
                <th className="headcol-compare label-compareBi">INCLUDES</th>
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <ul>
                      <li className="d-flex">
                        <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                        VOICE
                      </li>
                      {iGlobals.productProperties.filter(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.CHAT
                      ).length >= 1 && (
                        <li className="d-flex">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          />{" "}
                          CHAT
                        </li>
                      )}
                      {iGlobals.productProperties.filter(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.VIDEO
                      ).length >= 1 && (
                        <li className="d-flex">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          />{" "}
                          VIDEO
                        </li>
                      )}
                      {iGlobals.productProperties.filter(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.CONFERENCE
                      ).length >= 1 && (
                        <li className="d-flex">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          />{" "}
                          CONFERENCE
                        </li>
                      )}
                    </ul>
                  </td>
                ))}
              </tr>
              <CompareProperty
                title="WHY THIS CARRIER"
                propertyCode={EnumPropertyId.WHYTHISCARRIER}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="TECH SUPPORT"
                propertyCode={EnumPropertyId.TECH_SUPPORT}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="TECHNOLOGY"
                propertyCode={EnumPropertyId.TECHNOLOGY}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="LONG DISTANCE"
                propertyCode={EnumPropertyId.LONG_DISTANCE}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="SLA"
                propertyCode={EnumPropertyId.SLA}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="PHONE INCLUDED"
                propertyCode={EnumPropertyId.PHONE_LINE}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="INSTALLATION"
                propertyCode={EnumPropertyId.INSTALLATION}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="ADDS-ON"
                propertyCode={EnumPropertyId.ADDS_ON_SERVICES_STORE}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="EARLY CANCELLATION TERM"
                propertyCode={EnumPropertyId.EARLY_CANCELLATION_TERM}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="VOICE MAIL"
                propertyCode={EnumPropertyId.VOICE_MAIL}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="VOICEMAIL TO TEXT"
                propertyCode={EnumPropertyId.VOICEMAIL_TO_TEXT}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="CALL WAITING"
                propertyCode={EnumPropertyId.CALL_WAITING}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="THREE WAY CALLING"
                propertyCode={EnumPropertyId.THREE_WAY_CALLING}
                plansDataCompare={plansDataCompare}
              />
              <tr>
                <th className="headcol-compare" />
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.calculatedPrice.toFixed(2)}
                      data-speed="N/A"
                      data-linePhone="N/A"
                      data-term={plan.term}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )
      )}
    </>
  );
};

export default CompareVoice;
