import React, { useState, useEffect, useRef, useCallback } from 'react';
import { EnumViewPlansType, IResponseBundlePlansDTO, IResponseInternetPlansDTO, IWrSettings } from '../../../contracts/types';
import BundlePlanshort from './bundlePlanShort.comp';
import BundlePlanLarge from './bundlePlanLarge.comp';
import PlanTools from '../../planTools.comp';
import { useGlobalState } from '../../../state';
import Loading from '../../loading.comp';
import SeeMorePlans from '../seeMorePlans.comp';
import useBundlePlansPagination from '../../../hooks/api/useBundlePlansPagination';
import PlansFilters from '../plansFilters.comp';
import PlanAgentAvailable from '../planAgentAvailable.comp';

interface IBundlePlansProps {
    viewPlansType: EnumViewPlansType;
    wrSettings: IWrSettings;
}

const BundlePlans: React.FC<IBundlePlansProps> = ({ viewPlansType, wrSettings }) => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');
    const [iFilters] = useGlobalState('filters');

    const [planWS, setPlanWS] = useState<IResponseBundlePlansDTO>();
    const [pageNumber, setPageNumber] = useState(1);
    const [showLinkMorePlans, setShowLinkMorePlans] = useState(true);

    const { plans: data, hasMore, loading: isLoading, error: isError } = useBundlePlansPagination(viewPlansType, pageNumber);

    useEffect(() => {
        const planWire: IResponseBundlePlansDTO = {
            planId: -1,
            productId: -1,
            internalPlanId: -1,
            planDownloadSpeed: 0,
            planFiberFastQualType: '',
            planInstallPrice: 0,
            planModemIncluded: false,
            planName: 'wiresurfer',
            planNote: '',
            planPrice: 0,
            planRated: 0,
            planServiceTypeCode: '',
            planTechnologyCode: undefined,
            planTechnologyName: '',
            planTerm: 0,
            planTypeCode: undefined,
            planTypeName: 'Wiresurfer Agent Available',
            planUploadSpeed: 0,
            providerId: -1,
            providerLogo: 'logoWiresurferDummy.png',
            providerName: 'Wiresurfer',
            planLatitude: 0,
            planLongitude: 0,
        };

        setPlanWS(planWire);
    }, []);

    useEffect(() => {
        setPageNumber(1);
    }, [iFilters]);

    const observer = useRef<any>(null);
    const lastElementRef = useCallback(
        (node) => {
            if (isLoading) {
                return;
            }
            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore && !showLinkMorePlans) {
                    setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, hasMore, showLinkMorePlans]
    );

    const onClickShowLinkMorePlans = () => {
        setShowLinkMorePlans(false);
    };

    return (
        <>
            <div className="container">
                {!data ? (
                    <Loading typeName="Bundle providers nationwide!" />
                ) : (
                    <>
                        {data && viewPlansType === EnumViewPlansType.SHORT && (
                            <div className="contentThirdListPrices">
                                <div className="contentThirdListPrices">
                                    <div className="row ThirdListPrices">
                                        {data && data.length === 0 && (
                                            <div className="noResultFound">No providers available. But don't fret, we can still help by calling us at (toll-free #). We are busy loading new providers into our platform every week.</div>
                                        )}
                                        {data.map((plan: IResponseBundlePlansDTO, index: number) => {
                                            if (index <= 2) {
                                                const providerLogo = iGlobals.carriers.find((c) => c.id === plan.providerId);
                                                plan.providerLogo = providerLogo ? providerLogo.imageBase64 : '';

                                                return <BundlePlanshort key={plan.planId} plan={plan} />;
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            data && viewPlansType === EnumViewPlansType.LARGE && (
                                <>
                                    <PlansFilters />
                                    <section id="productsWS">
                                        <div className="container">
                                            <section className="mb-3">
                                                <PlanTools />
                                            </section>
                                            <div className="row resultWSgrid">
                                                {
                                                    data && data.length > 0 &&
                                                    data.slice(0, iFilters.filterOnlyBestPerAddress ? iPlanFilterParams.numberBestPlansPerAddress : data.filter((p) => !p.planHidden).length)
                                                        .map((plan, index) => {
                                                            if (plan.internalPlanId === -1 && data.length > 1) {
                                                                return <></>;
                                                            }

                                                            if (plan.planHidden) {
                                                                return <></>;
                                                            }

                                                            return <BundlePlanLarge key={index} plan={plan} lastElementRef={lastElementRef} />;
                                                        })
                                                }
                                            </div>
                                            {
                                                data && !hasMore &&
                                                <PlanAgentAvailable />
                                            }
                                            {
                                                data && data.length > 0 && hasMore && pageNumber === 1 && showLinkMorePlans &&
                                                <SeeMorePlans onClickSeeMorePlans={onClickShowLinkMorePlans} />
                                            }
                                        </div>
                                    </section>
                                </>
                            )}
                    </>
                )}
            </div>
        </>
    );
};

export default BundlePlans;
