import axios from 'axios';
import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints, NUM_RESULT_PLAN_TYPE_SHORT } from '../../api';
import { filtersPlansInternet } from '../../helpers/filtersPlansInternet';
import { EnumViewPlansType, IResponseInternetPlansDTO, IRequestInternetPlansDTO, IResponseParamsInternetPlansDTO, ISelectOption, IPlansByCodeProvider, EnumPlanType } from '../../contracts/types';
import { useGlobalState } from '../../state';

let cancelSource: any;
let cancelSourceATTIPB: any;
let cancelSourceATTADI: any;

export default function useInternetPlans(viewPlansType: EnumViewPlansType, codeProvider: string,term:number) {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');
    const [iGlobals, setIGlobals] = useGlobalState('globals');
    const api = useServiceApi();

    const doRequest = async (address: string, streetLine: string, city: string, state: string, country: string, zipCode: string, viewPlansType: EnumViewPlansType): Promise<IResponseParamsInternetPlansDTO> => {
        try {            
            const responseParams: IResponseParamsInternetPlansDTO = {
                codeProvider: codeProvider,
                term: term,
                address: address,
                lstResponseInternetPlansDTO: [],
                lstCarriers: [],
                lstProductProperties: [],
            };

            if (country !== 'US' || (viewPlansType === EnumViewPlansType.SHORT && codeProvider !== '' && zipCode === '-1')) {
                return responseParams;
            }

            if (window.location.href === iPlanFilterParams.baseUrl) {
                zipCode = '-1';
                if (viewPlansType === EnumViewPlansType.SHORT && codeProvider !== '' && zipCode === '-1') {
                    return responseParams;
                }
            }

            if (viewPlansType === EnumViewPlansType.SHORT && zipCode === '-1') {
                const path = window.location.pathname;
                streetLine = '';
                city = '';
                address = '';
                state = '';

                if (path.includes('search') || path.includes('business-phone')) {
                    return responseParams;
                } else if (path.includes('business-internet')) {
                    const pathCity = path.replace('/business-internet/', '').replace('/', '');
                    if (pathCity !== '') {
                        city = pathCity;
                    }
                }
            }

            const requestPlansDTO: IRequestInternetPlansDTO = {
                'codeProvider': codeProvider,
                'term': term,
                'streetLine': streetLine,
                'city': city,
                'zipCode': zipCode,
                'address': address,
                'state': state,
                'country': country,
                'viewPlansType': viewPlansType,
                'lstCarriersId': iGlobals.carriers.map((c) => c.id),
                'lstProductsId': iGlobals.productProperties.map((c) => c.productId),
            };

            if (iPlanFilterParams.idAddressMultiple === -1 && (!iPlanFilterParams.multipleAddresses || iPlanFilterParams?.multipleAddresses.multipleInternetParams.length === 0)) {
                cancelSearch(codeProvider, address);
            }

            const token = createToken(codeProvider, address);
            
            let apiUrl:string;
            if(codeProvider === "AT&T_IPBB"){
                apiUrl = ApiEndPoints.GetPlansInternetIPBB;
            }else if(codeProvider.includes("AT&T_ADI")){
                apiUrl = ApiEndPoints.GetPlansInternetADI;
            }else {
                apiUrl = ApiEndPoints.GetPlansInternet;
            }
            const{data} = await api.post<IResponseParamsInternetPlansDTO>(`${apiUrl}`, requestPlansDTO, { cancelToken: token });

            let arrayCarriers = iGlobals.carriers;
            data.lstCarriers.forEach((carrierDTO) => {
                const findCarrier = arrayCarriers.find((c) => c.id === carrierDTO.id);
                if (!findCarrier) {
                    arrayCarriers.push(carrierDTO);
                }
            });

            let arrayProPro = iGlobals.productProperties;
            data.lstProductProperties.forEach((productPropertyDTO) => {
                const findProPro = arrayProPro.find((pp) => pp.id === productPropertyDTO.id);
                if (!findProPro) {
                    arrayProPro.push(productPropertyDTO);
                }
            });

            let time = 0;
            if (codeProvider.length === 0) {
                cancelSource = null;
                time = 100;
            } else if (codeProvider === 'AT&T_IPBB') {
                cancelSourceATTIPB = null;
                time = 200;
            } else if (codeProvider === 'AT&T_ADI') {
                cancelSourceATTADI = null;
                time = 300;
            }

            const plansCompareProvider = iFilters.plansCompareInternet
                .filter((p) => p.codeProvider === data.codeProvider)
                .filter((pc) => data.lstResponseInternetPlansDTO.find((e) => e.planId === pc.idPlan && e.codeProvider === pc.codeProvider));
            const plansCompare = iFilters.plansCompareInternet.filter((pc) => pc.codeProvider !== data.codeProvider).concat(plansCompareProvider);

            if (iPlanFilterParams?.multipleAddresses && iFilters.showMoreLocations) {
                setTimeout(() => {
                    const internetParamData = iPlanFilterParams.multipleAddresses.multipleInternetParams.find((d) => d.address === data.address);

                    if (internetParamData) {
                        let codeProvData = data.codeProvider;
                        if (data.codeProvider.length === 0) {
                            codeProvData = 'OTHERS';
                        }

                        let plansByCodeProv = internetParamData.plansByCodeProvider.find(c => c.codeProvider === codeProvData);
                        if (!plansByCodeProv) {
                            plansByCodeProv = {
                                codeProvider: codeProvData,
                                plans: []
                            }
                        }

                        plansByCodeProv.plans = data.lstResponseInternetPlansDTO;

                        const arrayPlansByCodeProv = internetParamData.plansByCodeProvider.filter(c => c.codeProvider !== codeProvData);
                        arrayPlansByCodeProv.push(plansByCodeProv);
                        internetParamData.plansByCodeProvider = arrayPlansByCodeProv;

                        const arrayMultipleAddresses = iPlanFilterParams.multipleAddresses.multipleInternetParams.filter((d) => d.address !== data.address);
                        arrayMultipleAddresses.push(internetParamData);

                        let termsGroupBy = data.lstResponseInternetPlansDTO.map((item: IResponseInternetPlansDTO) => item.planTerm);
                        termsGroupBy = termsGroupBy.filter((value: any, index: any, self: any) => self.indexOf(value) === index);

                        let groupByTermsGlobal = iPlanFilterParams.groupByTerms;
                        termsGroupBy.forEach((term: number) => {
                            if (!groupByTermsGlobal.includes(term)) {
                                groupByTermsGlobal.push(term);
                            }
                        });

                        let providersGroupBy: ISelectOption[] = [];
                        data.lstResponseInternetPlansDTO.forEach((p) => {
                            const findProvider = providersGroupBy.find((f) => f.value === p.providerId);
                            if (!findProvider) {
                                const pr: ISelectOption = {
                                    value: p.providerId,
                                    label: p.providerName,
                                };
                                providersGroupBy.push(pr);
                            }
                        });

                        let groupByProvideresGlobal = iPlanFilterParams.groupByProviders;
                        providersGroupBy.forEach((prov: ISelectOption) => {
                            const findProvider = groupByProvideresGlobal.find((p) => p.value === prov.value);
                            if (!findProvider) {
                                groupByProvideresGlobal.push(prov);
                            }
                        });

                        setIPlanFilterParams({
                            ...iPlanFilterParams,
                            'groupByTerms': groupByTermsGlobal,
                            'groupByProviders': groupByProvideresGlobal,
                            'multipleAddresses': {
                                ...iPlanFilterParams.multipleAddresses,
                                'multipleInternetParams': arrayMultipleAddresses,
                            }
                        });

                        setIGlobals({
                            ...iGlobals,
                            'carriers': arrayCarriers,
                            'productProperties': arrayProPro,
                        });

                        setIFilters({
                            ...iFilters,
                            plansCompareInternet: plansCompare,
                        });
                    }
                }, time);
            } else {
                if (data.address === iPlanFilterParams.internetParams.address && zipCode !== '-1') {
                    let codeProvOther = data.codeProvider;
                    if (data.codeProvider.length === 0) {
                        codeProvOther = 'OTHERS';
                    }

                    let internetParams = iPlanFilterParams.internetParams;
                    let plansByCodeProv = internetParams.plansByCodeProvider.find(c => c.codeProvider === codeProvOther);
                    if (!plansByCodeProv) {
                        plansByCodeProv = {
                            codeProvider: codeProvOther,
                            plans: []
                        }
                    }

                    plansByCodeProv.plans = data.lstResponseInternetPlansDTO;

                    const arrayPlansByCodeProv = internetParams.plansByCodeProvider.filter(c => c.codeProvider !== codeProvOther);
                    arrayPlansByCodeProv.push(plansByCodeProv);

                    internetParams.plansByCodeProvider = arrayPlansByCodeProv;

                    let termsData = data.lstResponseInternetPlansDTO.map((item: IResponseInternetPlansDTO) => item.planTerm);
                    termsData = termsData.filter((value: any, index: any, self: any) => self.indexOf(value) === index);

                    let groupByTerms = iPlanFilterParams.groupByTerms;
                    termsData.forEach((term: number) => {
                        if (!groupByTerms.includes(term)) {
                            groupByTerms.push(term);
                        }
                    });

                    let providersData: ISelectOption[] = [];
                    data.lstResponseInternetPlansDTO.forEach((p) => {
                        const findProvider = providersData.find((f) => f.value === p.providerId);
                        if (!findProvider) {
                            const pr: ISelectOption = {
                                value: p.providerId,
                                label: p.providerName,
                            };
                            providersData.push(pr);
                        }
                    });

                    let groupByProviders = iPlanFilterParams.groupByProviders;
                    providersData.forEach((prov: ISelectOption) => {
                        const findProvider = groupByProviders.find((p) => p.value === prov.value);
                        if (!findProvider) {
                            groupByProviders.push(prov);
                        }
                    });

                    setIPlanFilterParams({
                        ...iPlanFilterParams,
                        'groupByTerms': groupByTerms,
                        'groupByProviders': groupByProviders,
                        'internetParams': internetParams,
                    });

                    setIGlobals({
                        ...iGlobals,
                        'carriers': arrayCarriers,
                        'productProperties': arrayProPro,
                    });

                    setIFilters({
                        ...iFilters,
                        plansCompareInternet: plansCompare,
                    });
                } else if (zipCode === '-1') {
                    setIGlobals({
                        ...iGlobals,
                        'carriers': arrayCarriers,
                        'productProperties': arrayProPro,
                    });
                }
            }

            return data;
        } catch (err) {
            if (axios.isCancel(err)) {
            }
        }

        const dataReturn: IResponseParamsInternetPlansDTO = {
            codeProvider: codeProvider,
            term: term,
            address: address,
            lstResponseInternetPlansDTO: [],
            lstCarriers: [],
            lstProductProperties: [],
        };

        return dataReturn;
    };

    const filterData = (data: IResponseParamsInternetPlansDTO) => {
        if (data.address === '') {
            data.lstResponseInternetPlansDTO = data.lstResponseInternetPlansDTO.slice(0, NUM_RESULT_PLAN_TYPE_SHORT);
            return data;
        }

        if (iFilters.showMoreLocations) {
            iPlanFilterParams.multipleAddresses.multipleInternetParams.forEach((internetParams) => {
                if (internetParams.address === data.address) {
                    const result = filtersPlansInternet(data.lstResponseInternetPlansDTO, iFilters);

                    // let plansByCodeProv = internetParams.plansByCodeProvider.find(c => c.codeProvider === data.codeProvider);
                    // if (!plansByCodeProv) {
                    //     plansByCodeProv = {
                    //         codeProvider: data.codeProvider,
                    //         plans: []
                    //     }
                    // }

                    // plansByCodeProv.plans = data.lstResponseInternetPlansDTO;

                    // const arrayPlansByCodeProv = internetParams.plansByCodeProvider.filter(c => c.codeProvider !== data.codeProvider);
                    // arrayPlansByCodeProv.push(plansByCodeProv);

                    // internetParams.plansByCodeProvider = arrayPlansByCodeProv;

                    data.lstResponseInternetPlansDTO = result;
                }
            });
        } else {
            let result = data.lstResponseInternetPlansDTO;
            if (result && result.length > 0) {
                result = filtersPlansInternet(data.lstResponseInternetPlansDTO, iFilters);
            }

            data.lstResponseInternetPlansDTO = result;
        }

        return data;
    };

    const createToken = (codeProvider: string, address: string) => {
        if (codeProvider.length === 0) {
            cancelSource = axios.CancelToken.source();
            cancelSource.message = address;
            return cancelSource.token;
        } else if (codeProvider === 'AT&T_IPBB') {
            cancelSourceATTIPB = axios.CancelToken.source();
            cancelSourceATTIPB.message = address;
            return cancelSourceATTIPB.token;
        } else if (codeProvider === 'AT&T_ADI') {
            cancelSourceATTADI = axios.CancelToken.source();
            cancelSourceATTADI.message = address;
            return cancelSourceATTADI.token;
        }
        return '';
    };

    const cancelSearch = (codeProvider: string, address: string) => {
        if (codeProvider.length === 0 && cancelSource) {
            cancelSource.cancel('Cancel ' + address);
        } else if (codeProvider === 'AT&T_IPBB' && cancelSourceATTIPB) {
            cancelSourceATTIPB.cancel('Cancel IPBB' + address);
        } else if (codeProvider === 'AT&T_ADI' && cancelSourceATTADI) {
            cancelSourceATTADI.cancel('Cancel ADI' + address);
        }
    };

    return useQuery<IResponseParamsInternetPlansDTO>(
        ['plansInternet', codeProvider, iPlanFilterParams.internetParams.streetLine, iPlanFilterParams.internetParams.city, iPlanFilterParams.internetParams.zipCode, iPlanFilterParams.internetParams.secondary, EnumPlanType.BUSINESS_INTERNET],
        () =>
            doRequest(
                iPlanFilterParams.internetParams.address,
                iPlanFilterParams.internetParams.streetLine,
                iPlanFilterParams.internetParams.city ? iPlanFilterParams.internetParams.city : '',
                iPlanFilterParams.internetParams.state ? iPlanFilterParams.internetParams.state : '',
                iPlanFilterParams.internetParams.country ? iPlanFilterParams.internetParams.country : 'US',
                iPlanFilterParams.internetParams.zipCode,
                iPlanFilterParams.internetParams.viewPlansType ? iPlanFilterParams.internetParams.viewPlansType : EnumViewPlansType.SHORT
            ),
        {
            select: filterData,
            staleTime: 60 * 1000 * 60 /*5 minutes*/,
            enabled: iPlanFilterParams.internetParams.zipCode !== '',
            retry: 1,
            notifyOnChangeProps: ['data', 'error'],
        }
    );
}
