import React, { useEffect } from "react";
import {
  EnumPlanType,
  IMultipleAddresses,
  ISmartyResponseDTO,
} from "../../contracts/types";
import { IPlanFilterParamsInternet } from "../../contracts/types";
import { useGlobalState } from "../../state";
import AddressText from "./addressText.comp";

interface ILineMultipleProps {
  internetParams?: IPlanFilterParamsInternet;
  line: number;
  onChangeAddress: (e: any) => void;
  onClickSelectAddressSuggestions: (
    smartyResponseDTO: ISmartyResponseDTO
  ) => void;
  onClickMoreLocations: (e: any) => void;
  onClickSearchInternet: (e: any) => void;
}

const LineMultiple: React.FC<ILineMultipleProps> = ({
  internetParams,
  line,
  onChangeAddress,
  onClickSelectAddressSuggestions,
  onClickMoreLocations,
  onClickSearchInternet,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");

  let numberLine = line;
  if (line === -1) {
    numberLine = 1;
    if (iPlanFilterParams.multipleAddresses.multipleInternetParams.length > 0) {
      numberLine =
        iPlanFilterParams.multipleAddresses.multipleInternetParams.length + 1;
    }
  }

  const onClickAddLocation = () => {
    const maxAddress =
      iPlanFilterParams.multipleAddresses.multipleInternetParams &&
      iPlanFilterParams.multipleAddresses.multipleInternetParams.length > 0 &&
      iPlanFilterParams.multipleAddresses.multipleInternetParams.reduce(
        function (prev, current) {
          return prev.idMultiple > current.idMultiple ? prev : current;
        }
      );
    const idMultiple = maxAddress ? maxAddress.idMultiple + 1 : 1;

    let newAddress: IPlanFilterParamsInternet = {
      streetLine: "",
      city: "",
      zipCode: "",
      address: "",
      addressDetail: "",
      state: "",
      secondary: "",
      latitude: 0,
      longitude: 0,
      country: "US",
      idMultiple: idMultiple,
      openedGroupAddressPlans: false,
      smartyAddress: null,
      plansByCodeProvider: [],
      plan: {
        planId: 0,
        productId: 0,
        internalPlanId: 0,
        planName: "",
        planServiceTypeCode: "",
        planTypeCode: EnumPlanType.BUSINESS_INTERNET,
        planTypeName: "",
        providerId: 0,
        providerName: "",
        providerLogo: "",
        planRated: 0,
        planFiberFastQualType: "",
        planTechnologyName: "",
        planDownloadSpeed: 0,
        planUploadSpeed: 0,
        planTerm: 0,
        planModemIncluded: false,
        planPrice: 0,
        planInstallPrice: 0,
        planLatitude: 0,
        planLongitude: 0,
        planNote: "",
        planHidden: false,
        planServiceTypeName: "",
        learnMore: "",
        codeProvider: "",
      },
    };

    const multipleAddresses: IMultipleAddresses =
      iPlanFilterParams.multipleAddresses;
    let arrayMultipleParams: IPlanFilterParamsInternet[] =
      multipleAddresses === undefined ||
      multipleAddresses.multipleInternetParams === null
        ? []
        : multipleAddresses.multipleInternetParams;
    arrayMultipleParams.push(newAddress);

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: arrayMultipleParams,
      },
    });
  };

  const onClickRemoveLocation = (idMultiple: number) => {
    const multipleAddresses: IMultipleAddresses =
      iPlanFilterParams.multipleAddresses;
    const arrayMultipleParams: IPlanFilterParamsInternet[] =
      multipleAddresses === undefined ||
      multipleAddresses.multipleInternetParams === null
        ? []
        : multipleAddresses.multipleInternetParams;
    const multipleLoctions = arrayMultipleParams.filter(
      (ip) => ip.idMultiple !== idMultiple
    );

    if (arrayMultipleParams.length === 1) {
      let newAddress: IPlanFilterParamsInternet = {
        streetLine: "",
        city: "",
        zipCode: "",
        address: "",
        addressDetail: "",
        state: "",
        secondary: "",
        latitude: 0,
        longitude: 0,
        country: "US",
        idMultiple: 1,
        openedGroupAddressPlans: false,
        smartyAddress: null,
        plansByCodeProvider: [],
        plan: {
          planId: 0,
          productId: 0,
          internalPlanId: 0,
          planName: "",
          planServiceTypeCode: "",
          planTypeCode: EnumPlanType.BUSINESS_INTERNET,
          planTypeName: "",
          providerId: 0,
          providerName: "",
          providerLogo: "",
          planRated: 0,
          planFiberFastQualType: "",
          planTechnologyName: "",
          planDownloadSpeed: 0,
          planUploadSpeed: 0,
          planTerm: 0,
          planModemIncluded: false,
          planPrice: 0,
          planInstallPrice: 0,
          planLatitude: 0,
          planLongitude: 0,
          planNote: "",
          planHidden: false,
          planServiceTypeName: "",
          learnMore: "",
          codeProvider: "",
        },
      };

      multipleLoctions.push(newAddress);
    }

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: multipleLoctions,
      },
    });
  };

  const onClickSelectSuggestions = (smartyResponse: ISmartyResponseDTO) => {
    let intParam =
      iPlanFilterParams.multipleAddresses.multipleInternetParams.find(
        (i) => i.idMultiple === internetParams?.idMultiple
      );

    if (intParam) {
      setTimeout(function () {
        const address = `${smartyResponse.street_line} ${smartyResponse.city}`;
        const entries =
          smartyResponse.entries > 1
            ? ` (${smartyResponse.entries} more entries)`
            : "";
        const addressDetail = `${smartyResponse.street_line}, ${
          smartyResponse.secondary !== ""
            ? `${smartyResponse.secondary}${entries}, `
            : ""
        }${smartyResponse.city}, ${smartyResponse.state} ${
          smartyResponse.zipcode
        }`;

        intParam = {
          streetLine: smartyResponse.street_line,
          city: smartyResponse.city,
          zipCode: smartyResponse.zipcode,
          address: address,
          addressDetail: addressDetail,
          state: smartyResponse.state,
          secondary: smartyResponse.secondary,
          latitude: 0,
          longitude: 0,
          country: "US",
          idMultiple: internetParams ? internetParams?.idMultiple : 0,
          openedGroupAddressPlans: false,
          smartyAddress: smartyResponse,
          plansByCodeProvider: [],
          plan: {
            planId: 0,
            productId: 0,
            internalPlanId: 0,
            planName: "",
            planServiceTypeCode: "",
            planTypeCode: EnumPlanType.BUSINESS_INTERNET,
            planTypeName: "",
            providerId: 0,
            providerName: "",
            providerLogo: "",
            planRated: 0,
            planFiberFastQualType: "",
            planTechnologyName: "",
            planDownloadSpeed: 0,
            planUploadSpeed: 0,
            planTerm: 0,
            planModemIncluded: false,
            planPrice: 0,
            planInstallPrice: 0,
            planLatitude: 0,
            planLongitude: 0,
            planNote: "",
            planHidden: false,
            planServiceTypeName: "",
            learnMore: "",
            codeProvider: "",
          },
        };

        const arrayIntParam =
          iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(
            (i) => i.idMultiple !== internetParams?.idMultiple
          );
        arrayIntParam.push(intParam);

        setIPlanFilterParams({
          ...iPlanFilterParams,
          multipleAddresses: {
            ...iPlanFilterParams.multipleAddresses,
            multipleInternetParams: arrayIntParam,
          },
        });
      }, 200);
    }

    onClickSelectAddressSuggestions(smartyResponse);

    setTimeout(function () {}, 200);
  };

  return (
    <>
      <div className="col-md-7 col-6">
        <div className="input-group mb-2">
          {" "}
          <AddressText
            isMultipleAddress={false}
            isBillingAddress={false}
            placeHolder={`Enter Address ${numberLine === 1 ? "" : numberLine}`}
            onChangeAddressParent={onChangeAddress}
            onClickSelectAddressParent={onClickSelectSuggestions}
            cssInputAddress="form-control form-control input-address"
            smartyDTO={internetParams && internetParams.smartyAddress}
          />
        </div>
      </div>
      <div className="col-md-3 col-6">
        <div className="row">
          <div className="col-3">
            {" "}
            <button className="bi-input" onClick={onClickAddLocation}>
              <i className="fa fa-plus align-middle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-6">
            <div className="mt-2">
              {" "}
              <span className="morelocation">More locations</span>
            </div>
          </div>
          <div className="col-3">
            {" "}
            <button
              className="bi-input"
              onClick={() =>
                onClickRemoveLocation(
                  internetParams ? internetParams?.idMultiple : 0
                )
              }
            >
              <i className="fa fa-trash-o align-middle " aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-2 col-6 mostrar-moredesktop">
        {line === 1 && (
          <div className="form-check form-switch form-switch-md mt-2">
            {" "}
            <input
              className="form-check-input"
              type="checkbox"
              id="LocationSwitch"
              value="yes"
              onClick={onClickMoreLocations}
              checked={iFilters.showMoreLocations}
            />{" "}
            <label
              className="form-check-label morelocation"
              htmlFor="LocationSwitch"
            >
              {" "}
              More locations
            </label>
          </div>
        )}
      </div>
      {iPlanFilterParams.multipleAddresses.multipleInternetParams.length ===
        line && (
        <>
          <div className="col-md-7 mostrar-moredesktop">
            {" "}
            <button
              type="button"
              className="btn btn-lg searchButton"
              onClick={onClickSearchInternet}
            >
              Search
            </button>
          </div>
          <div className="col-md-7 col-6 mostrar-moremobile">
            <button
              type="button"
              className="btn btn-lg searchButton"
              onClick={onClickSearchInternet}
            >
              Search
            </button>
          </div>
          <div className="col-md-2 col-6 mostrar-moremobile">
            <div className="form-check form-switch form-switch-md mt-2">
              {" "}
              <input
                className="form-check-input"
                type="checkbox"
                id="LocationSwitch"
                value="yes"
                onClick={onClickMoreLocations}
                checked={iFilters.showMoreLocations}
              />{" "}
              <label
                className="form-check-label morelocation"
                htmlFor="LocationSwitch"
              >
                {" "}
                More locations
              </label>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LineMultiple;
