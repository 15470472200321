import React, { useEffect, useState } from "react";

import {
  EnumPropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IVoicePlanLargeMobileProps {
  plan: IResponseVoicePlansDTO;
  onChangeCompareCheckBox: (planId: number) => void;
}

const VoicePlanLargeMobile: React.FC<IVoicePlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  return (
    <div className="row align-middle">
      <div className="col-12 p-2">
        <img
          className="mx-auto d-block"
          src={`data:image/jpeg;base64,${
            iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64
          }`}
          alt=""
        />
      </div>
      {/* <div className="col-6 text-center p-2">
        <div className="card-form-check">
          <div
            className={
              iFilters.plansCompareVoice.find((p) => p === plan.planId)
                ? "circle-check-on"
                : "circle-check"
            }
            onClick={() => onChangeCompareCheckBox(plan.planId)}
          />
          {iFilters.plansCompareVoice &&
          iFilters.plansCompareVoice.length >= 1 ? (
            <span
              className="card-compareplan link-url"
              onClick={onClickCompareLink}
            >
              Compare Plans
            </span>
          ) : (
            <span className="card-compareplan">Compare Plans</span>
          )}
        </div>
      </div> */}
      {/* <div className="col-6 card-tr-title p-2 mobile-espace">NAME</div> */}
      <div className="col-12 text-center p-2">
        <p className="voice-bolt-bp service-type-name-mobile">
          {plan.serviceTypeName}
        </p>
      </div>
      <div className="col-6 card-tr-title p-2">PRICE</div>
      <div className="col-6 text-center p-2">
        {/* <span className="card-price"></span> */}
        <span className="voice-bolt">
          ${plan.calculatedPrice.toFixed(2)} mo
        </span>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">TERM</div>
      <div className="col-6 text-center p-2">
        <div>
          {/* <span className="card-max-term"></span> */}
          <div className="voice-bolt">{plan.term} MONTHS</div>
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2 mobile-espace">INCLUDES</div>
      <div className="col-6 text-center p-2 ">
        <ul id="list-includes-phone-mb" className="card-includesBP">
          <li className="d-flex">
            {/* <i className="fa fa-check-circle" aria-hidden="true" />  */}
            VOICE
          </li>
          {iGlobals.productProperties.filter(
            (pp) =>
              pp.productId === plan.productId &&
              pp.propertyCode === EnumPropertyId.CHAT
          ).length >= 1 && (
            <li className="d-flex">
              {/* <i className="fa fa-check-circle" aria-hidden="true" />  */},
              CHAT
            </li>
          )}
          {iGlobals.productProperties.filter(
            (pp) =>
              pp.productId === plan.productId &&
              pp.propertyCode === EnumPropertyId.VIDEO
          ).length >= 1 && (
            <li className="d-flex">
              {/* <i className="fa fa-check-circle" aria-hidden="true" />  */},
              VIDEO
            </li>
          )}
          {iGlobals.productProperties.filter(
            (pp) =>
              pp.productId === plan.productId &&
              pp.propertyCode === EnumPropertyId.CONFERENCE
          ).length >= 1 && (
            <li className="d-flex">
              {/* <i className="fa fa-check-circle" aria-hidden="true" />  */},
              CONFERENCE
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default VoicePlanLargeMobile;
