import React from 'react';
import { EnumVoicePlanFeatures, IMinMax, IFilterRange, IFilterCheckbox, EnumFilterType } from "../../../contracts/types";
import { useGlobalState } from '../../../state';
import FilterRange from "../filter/filterRange.comp"
import FilterListCheckbox from "../filter/filterListCheckbox.comp"
import useAuditSaveFilter from '../../../hooks/api/useAuditSaveFilter';

const VoicePlanFilters: React.FC = () => {

    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');

    const { } = useAuditSaveFilter();

    const priceOptions: IFilterRange[] = [
        { minMax: { min: 0, max: 100 }, label: 'Under $100' },
        { minMax: { min: 100, max: 200 }, label: '$100 to $200' },
        { minMax: { min: 200, max: 300 }, label: '$200 to $300' },
        { minMax: { min: 300, max: 400 }, label: '$300 to $400' },
        { minMax: { min: 400, max: 0 }, label: '$400 & Above' }
    ];

    const onChangePriceValue = (minMax: IMinMax) => {
        setIPlanFilterParams({
            ...iPlanFilterParams, voiceParams: {
                ...iPlanFilterParams.voiceParams,
                filterPrice: minMax
            }
        });

        setAuditFilter(EnumFilterType.PRICE, minMax.min.toString(), minMax.max.toString());
    };

    const termOptions: IFilterCheckbox[] = iPlanFilterParams.voiceParams.termsGroupBy?.sort().map((term: number) => (
        { value: term, label: `${term} months` }
    ));

    const onChangeTermValues = (values: number[]) => {
        setIPlanFilterParams({
            ...iPlanFilterParams, voiceParams: {
                ...iPlanFilterParams.voiceParams,
                filterTerm: values
            }
        });

        setAuditFilter(EnumFilterType.TERM, values.toString(), '');
    };

    const featureOptions: IFilterCheckbox[] = [
        // { value: EnumVoicePlanFeatures.METERED, label: 'Calling Plans' },
        { value: EnumVoicePlanFeatures.UNLIMITED, label: 'Unlimited Calling' },
        { value: EnumVoicePlanFeatures.CHATIM, label: 'Chat' },
        { value: EnumVoicePlanFeatures.MEETVIDEO, label: 'Meet' }
    ];

    const onChangeFeatureValues = (values: number[]) => {

        setIPlanFilterParams({
            ...iPlanFilterParams, voiceParams: {
                ...iPlanFilterParams.voiceParams,
                filterPlanFeatures: values
            }
        });

        setAuditFilter(EnumFilterType.FEATURES, values.toString(), '');
    };

    const setAuditFilter = (type: EnumFilterType, value1: string, value2: string) => {
        if (type === EnumFilterType.PRICE || type === EnumFilterType.SPEED) {
            if (value1 === '0' && value2 === '0') {
                return;
            }
        }

        setIAuditVisitor(
            {
                ...iAuditVisitor,
                "searchFilter": {
                    ...iAuditVisitor.searchFilter,
                    "id": -1,
                    "visitorSearchId": iAuditVisitor.search.id,
                    "filterType": type,
                    "value1": value1,
                    "value2": value2
                },
            });
    };

    return (
        <>
            <FilterRange label='Price' options={priceOptions} optionValue={iPlanFilterParams.voiceParams.filterPrice} onChangeValue={onChangePriceValue} />
            <FilterListCheckbox label='Term' filterValues={iPlanFilterParams.voiceParams.filterTerm} options={termOptions} onChangeValue={onChangeTermValues} />
            <FilterListCheckbox label='Features' filterValues={iPlanFilterParams.voiceParams.filterPlanFeatures} options={featureOptions} onChangeValue={onChangeFeatureValues} />
        </>
    );
};

export default VoicePlanFilters;