// import { NumberLiteralType } from "typescript";

export interface ISession {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
    scope: string;
    isGoogle: boolean;
}

export interface IFilters {
    sortBy: EnumSortBy;
    sortByCompare: EnumSortBy;
    filterPrice: IMinMax;
    filterSpeed: IMinMax;
    filterTerm: number[];
    filterPlanFeatures: EnumInternetPlanFeatures[];
    filterProvider: number[];
    filterOnlyBestPerAddress: boolean | null;
    viewPlansLargeTypeInternet: EnumViewPlansLargeType;
    planTypeCode: EnumPlanType;
    showMoreLocations: boolean;
    plansCompareInternet: IPlansCompare[];
    plansCompareVoice: number[];
    plansCompareBundle: IPlansCompare[];
    plansCompareMultiple: IPlansCompareMultiple[];
    tabShoppingCart: EnumPlanType;
    showClickToDial: boolean;
}

export interface IGlobals {
    carriers: IResponseCarriersDTO[];
    productProperties: IResponseProductPropertyDTO[];
    planLearMore: IResponseInternetPlansDTO | null;
}

export interface IPlanFilterParams {
    baseUrl: string;
    isLocalhost: boolean;
    wiresurferApi: string;
    imagesUrl: string;
    internetParams: IPlanFilterParamsInternet;
    voiceParams: IPlanFilterParamsVoice;
    bundleParams: IPlanFilterParamsBundle;
    surveyData: ISurveyData;
    surveyResponse: ISurveyResponse;
    pages: IPageSurveyDTO[];
    multipleAddresses: IMultipleAddresses;
    idAddressMultiple: number;
    someSelectedAddress?: boolean | null;
    selectedAllAddress?: boolean | null;
    numberBestPlansPerAddress: number | 5;
    nextPageOrder: boolean | false;
    isClickCalendly: boolean | false;
    billingAddress: string | '';
    billingAddresSelected: string | '';
    billingSmarty: ISmartyResponseDTO | null;
    parameters: IParametersResponseDTO | null;
    verifyQuestionResponse: boolean | false;
    smartyAddressInternet: ISmartyResponseDTO | null;
    smartyAddressBundle: ISmartyResponseDTO | null;
    subProductsPlan: IOrderSuProductDTO[];
    removeQuery: boolean | false;
    groupByTerms: number[];
    groupByProviders: ISelectOption[];
}

export interface IMultipleAddresses {
    multipleInternetParams: IPlanFilterParamsInternet[];
    connectionType: EnumConnectionType;
    openedBulkEdit?: boolean | null;
}

export interface ISurveyData {
    isOpen: boolean;
    providerId: number;
    planId: number;
    internalPlanId: number;
    orderId: number | null;
    isPageThanks: boolean;
    urlOrderSummary: string | '';
    encryptOrderId: string | null;
    disabledTBI: boolean | null;
    currentPageIndex: number | 0;
}

export interface ISurveyResponse {
    orderId: number | null;
    encryptOrderId: string | null;
    orderAddressesDTO: IOrderAddressesDTO[];
    surveyQuestionsResponse: ISurveyQuestionResponse[];
    isFinish: boolean | false;
    baseUrl?: string | null;
    billingSmarty?: ISmartyResponseDTO | null;
    urlOrderSummary: string | '';
    planVoice?: IResponseVoicePlansDTO | undefined;
    planTypeCode?: EnumPlanType | null;
    featuresPlan: IOrderFeatureDTO[] | [];
    equipmentsVoice: IOrderVoiceEquipmentDTO[];
    arrayVisitorSearchId: number[];
    referred: string;
    visitorId: number;
}

export interface IOrderAddressesDTO {
    orderId: number | null;
    address?: string | null;
    providerId?: number | null;
    internalPlanId?: number | null;
    planTypeCode?: string | null;
    responseInternetPlans?: IResponseInternetPlansDTO;
    responseBundlePlans?: IResponseBundlePlansDTO;
    responseVoicePlans?: IResponseVoicePlansDTO;
    streetLine?: string | null;
    zipCode?: string | null;
    city?: string | null;
    state?: string | null;
    secondary?: string | null;
}

export interface ISurveyQuestionResponse {
    questionId: number;
    label: string;
    value: string;
    isValid: boolean | null;
}

export interface IPlanFilterParamsInternet {
    streetLine: string;
    zipCode: string;
    address: string;
    addressDetail: string;
    latitude: number;
    longitude: number;
    city?: string | null;
    state?: string | null;
    secondary?: string | null;
    country: string | null;
    plan: IResponseInternetPlansDTO;
    selectAddress?: boolean | null;
    idMultiple: number;
    openedGroupAddressPlans?: boolean | null;
    viewPlansType?: EnumViewPlansType | EnumViewPlansType.SHORT;
    smartyAddress: ISmartyResponseDTO | null;
    plansByCodeProvider: IPlansByCodeProvider[];
}

export interface IPlansByCodeProvider {
    codeProvider: string;
    plans: IResponseInternetPlansDTO[];
}

export interface IPlansByAddress {
    address: string;
    plans: IResponseInternetPlansDTO[];
}

export interface IPlansCompareMultiple {
    address: string;
    plansCompare: IPlansCompare[];
}

export interface IPlansCompare {
    idPlan: number;
    codeProvider?: string;
    planName: string;
    planPrice: number;
}

export interface IPlanFilterParamsBundle {
    streetLine: string;
    zipCode: string;
    address: string;
    addressDetail: string;
    latitude: number;
    longitude: number;
    city?: string | null;
    state?: string | null;
    secondary?: string | null;
    country: string | null;
    plan?: IResponseBundlePlansDTO | null;
    viewPlansType?: EnumViewPlansType | EnumViewPlansType.SHORT;
    smartyAddress: ISmartyResponseDTO | null;
}

export interface IPlanFilterParamsVoice {
    termsGroupBy: number[];
    filterNumber: number;
    filterPrice: IMinMax;
    filterTerm: number[];
    filterPlanFeatures: EnumVoicePlanFeatures[];
    filterEquipmentId: number | null;
    viewPlansLargeType: EnumViewPlansLargeType;
    sortBy: EnumSortBy;
    filterConfigSubProducts: ISubProductsPlans[];
    equipmentConfig: IEquipmentConfig[];
    plan?: IResponseVoicePlansDTO | undefined;
}

export interface IEquipmentConfig {
    equipmentId: number;
    equipmentName: string;
    equipmentValueNumber: number;
    equipmentPlanConfig: IEquipmentPlanConfig[];
}

export interface IEquipmentPlanConfig {
    planId: number;
    nEquipments: number;
    productId: number;
    price: number;
}

export interface ISelectOption {
    value: number;
    label: string;
}

export interface IWrSettings {
    name: string;
    redirect: string;
    redirectOrderNow: string;
    redirectMultiple: string;
    redirectChoosePlan: string;
    googleApiKey: string;
    wiresurferApi: string;
    imagesUrl: string;
    termsGroupBy: number[];
}

export interface IMinMax {
    min: number;
    max: number;
}

export interface IFilterRange {
    minMax: IMinMax;
    label: string;
}

export interface IFilterCheckbox {
    value: number;
    label: string;
}

export enum EnumViewPlansType {
    SHORT,
    LARGE,
}

export enum EnumViewPlansLargeType {
    DETAILS,
    COMPACT,
    MULTIADDRESS,
}

export enum EnumPlanType {
    BUSINESS_INTERNET = 'BUSINESS_INTERNET',
    BUSINESS_VOICE_PHONE = 'BUSINESS_VOICE_PHONE',
    BUSINESS_BUNDLES = 'BUSINESS_BUNDLES',
}

export enum EnumPlanTechnologyType {
    CABLE = 'BROADBAND',
    DSL = 'DSL',
    FIBER = 'FIBER',
    HFC = 'HFC',
    METERED = 'METERED',
    VOIP = 'VOIP',
}

export interface IPropertiesPlans {
    id: number;
    name: string;
    code: string;
    plans: IPlanProperty[];
}

export interface IPlanProperty {
    productId: number;
    propertyId: number;
    formula: string;
}

export interface ISubProductsPlans {
    id: number;
    name: string;
    code: string;
    filterConfig: number;
    plans: IPlanSubProductsDTO[];
}

export interface IOrderSuProductDTO {
    orderId: number | null;
    subProductId: number;
    description: string;
    subProductNumber: number;
    unitPrice: number;
}

export enum EnumInternetPlanFeatures {
    CABLE,
    DSL,
    FIBER,
    HFC,
    METERED,
    EQUIPMENT_INCLUDED,
}

export enum EnumVoicePlanFeatures {
    METERED,
    UNLIMITED,
    CHATIM,
    MEETVIDEO,
}

export interface IOptionSelect {
    value: EnumSortBy;
    label: string;
}

export interface ISortBy {
    value: EnumSortBy;
    label: string;
}

export enum EnumSortBy {
    NONE,
    LOW_TO_HIGH_PRICE,
    HIGH_TO_LOW_PRICE,
    LOW_TO_HIGH_SPEED,
    HIGH_TO_LOW_SPEED,
}

export interface IConnectionType {
    value: EnumConnectionType;
    label: string;
}

export enum EnumConnectionType {
    NOTHING,
    SINCHRONOUS,
    NOTSINCHRONOUS,
}

export enum EnumHTTPMessage {
    REQUEST = 'REQUEST',
    RESPONSE = 'RESPONSE',
    EMPTY = '',
}

export enum EnumSearchType {
    HOME = 'HOM',
    SEARCH = 'SEA',
    MULTIPLE = 'MUL',
}

export enum EnumFilterType {
    NONE,
    PRICE,
    SPEED,
    TERM,
    INTERNET_TYPE,
    PROVIDER,
    BESTPERADDRESS,
    FEATURES,
}

export enum EnumPropertyId {
    SLA = 'SLA',
    INSTALLATION = 'INSTALLATION',
    STATIC_IP = 'FIXED_IP_COST',
    RECOMMENDED_FOR = 'RECOMMENDED_FOR',
    DEDICATED_SHARED_INTERNET = 'DEDICATED_SHARED_INTERNET',
    TECH_SUPPORT = 'TECH_SUPPORT',
    TECHNOLOGY = 'TECHNOLOGY',
    WHYTHISCARRIER = 'WHY_THIS_CARRIER',
    EQUIPMENT_INCLUDED = 'EQUIPMENT_INCLUDED',
    SYM_ASYM_CONNECTION = 'SYMMETRICAL_ASYMMETRICAL_CONNECTION',
    PRICE_GUARANTEE = 'PRICE_GUARANTEE',
    SALES_DESCRIPTION = 'SALES_DESCRIPTION',
    LONG_DISTANCE = 'LONG_DISTANCE',
    CHAT = 'CHAT',
    CONFERENCE = 'CONFERENCE',
    VIDEO = 'VIDEO',
    PHONE_LINE = 'PHONE_LINE',
    CONNECTED_DEVICES = 'CONNECTED_DEVICES',
    FIXED_IP_COST = 'FIXED_IP_COST',
    ADDS_ON_SERVICES_STORE = 'ADDS_ON_SERVICES_STORE',
    EARLY_CANCELLATION_TERM = 'EARLY_CANCELLATION_TERM',
    VOICE_MAIL = 'VOICE_MAIL',
    VOICEMAIL_TO_TEXT = 'VOICEMAIL_TO_TEXT',
    CALL_WAITING = 'CALL_WAITING',
    THREE_WAY_CALLING = 'THREE_WAY_CALLING',
    DEALS = 'DEAL',
    DIFERENTS_ATTRIBUTES = 'DIFFERENT_ATTRIBUTES',
    SECURITY = 'SECURITY',
    AUTO_PAY = 'AUTO_PAY_AND_PAPERLESS_BILLING_DISCOUNT',
    GIFT = 'GIFT',
    KEY_FEATURES = 'KEY_FEATURES',
    UNLIMITED_INTERNET = 'UNLIMITED_INTERNET_DATA_CALLS',
    ADDITIONAL_FEATURES = 'ADDITIONAL_FEATURES',
    BEST_FOR = 'THIS_PLAN_WORKS_BEST_FOR',
    CUSTOMERCARE = 'CUSTOMERCARE',
    INTERNET_SECURITY = 'INTERNET_SECURITY',
    RELIABILITY_AND_SUPPORT = 'RELIABILITY_AND_SUPPORT',
    WIRELESS_NETWORKING = 'WIRELESS_NETWORKING',
    TRIAL_PERIOD_OR_MONEY_BACK_GUARANTEE = 'TRIAL_PERIOD_OR_MONEY_BACK_GUARANTEE',
    HOTSPOT_WIFI = 'HOTSPOT_WIFI',
    EMAIL_ACCOUNTS_INCLUDED = 'EMAIL_ACCOUNTS_INCLUDED',
    WEB_SITE = 'WEB_SITE',
    LEARN = 'LEARN',
    EQUIPMENT_FEE = 'EQUIPMENT_FEE',
    UNLIMITED_INTERNET_DATA = 'UNLIMITED_INTERNET_DATA',
}

/*Request and Response Interfaces plans*/
export interface IRequestBundlePlansDTO {
    streetLine: string;
    city: string;
    zipCode: string;
    state: string;
    address: string;
    country: string;
    viewPlansType: EnumViewPlansType;
    lstCarriersId: number[];
    lstProductsId: number[];
}

export interface IResponseBundlePlansDTO {
    planId: number;
    productId: number;
    internalPlanId: number;
    planName: string;
    planServiceTypeCode: string;
    planTypeCode?: EnumPlanType;
    planTypeName: string;
    providerId: number;
    providerName: string;
    providerLogo: string;
    planRated: number;
    planFiberFastQualType: string;
    planTechnologyCode?: EnumPlanTechnologyType;
    planTechnologyName: string;
    planDownloadSpeed: number;
    planUploadSpeed: number;
    planTerm: number;
    planModemIncluded: boolean;
    planPrice: number;
    planInstallPrice?: number;
    planLatitude: number;
    planLongitude: number;
    planNote: string;
    planHidden?: boolean | false;
    planServiceTypeName?: string;
    learnMore?: string;
}

export interface IResponseParamsBundlePlansDTO {
    address: string;
    lstResponseBundlePlansDTO: IResponseBundlePlansDTO[] | [];
    lstCarriers: IResponseCarriersDTO[];
    lstProductProperties: IResponseProductPropertyDTO[];
}

export interface IRequestInternetPlansDTO {
    codeProvider: string;
    term: number;
    streetLine: string;
    city: string;
    zipCode: string;
    state: string;
    address: string;
    country: string;
    viewPlansType: EnumViewPlansType;
    lstCarriersId: number[];
    lstProductsId: number[];
}

export interface IResponseInternetPlansDTO {
    planId: number;
    productId: number;
    internalPlanId: number;
    planName: string;
    planServiceTypeCode: string;
    planTypeCode?: EnumPlanType;
    planTypeName: string;
    providerId: number;
    providerName: string;
    providerLogo: string;
    planRated: number;
    planFiberFastQualType: string;
    planTechnologyCode?: EnumPlanTechnologyType;
    planTechnologyName: string;
    planDownloadSpeed: number;
    planUploadSpeed: number;
    planTerm: number;
    planModemIncluded: boolean;
    planPrice: number;
    planInstallPrice?: number;
    planLatitude: number;
    planLongitude: number;
    planNote: string;
    planHidden?: boolean | false;
    planServiceTypeName?: string;
    learnMore?: string;
    codeProvider?: string;
    isLanding?: boolean;
    promoDescription?: string;
}

export interface IResponseParamsInternetPlansDTO {
    codeProvider: string;
    term: number;
    address: string;
    lstResponseInternetPlansDTO: IResponseInternetPlansDTO[] | [];
    lstCarriers: IResponseCarriersDTO[];
    lstProductProperties: IResponseProductPropertyDTO[];
}

export interface IResponseParamsVoicePlansDTO {
    lstResponseVoicePlansDTO: IResponseVoicePlansDTO[] | [];
    lstCarriers: IResponseCarriersDTO[];
    lstProductProperties: IResponseProductPropertyDTO[];
}

export interface IResponseSubProductDTO {
    id: number;
    name: string;
    code: string;
}

export interface IResponsePlanPropertiesDTO {
    id: number;
    code: string;
    productId: number;
    propertyId: number;
    formula: string;
    filterConfig: number;
}

export interface IResponsePlanSubProductsDTO {
    id: number;
    productId: number;
    subProductId: number;
    term: number;
    description: string;
    filterConfig: number;
    quantity: number;
    unitPrice: number;
}

export interface IPlanSubProductsDTO {
    id: number;
    productId: number;
    subProductId: number;
    term: number;
    description: string;
    filterConfig: number;
    values: IPlanSubProductsValuesDTO[];
}

export interface IPlanSubProductsValuesDTO {
    quantity: number;
    unitPrice: number;
}

export interface IResponseFeatureDetailsDTO {
    propertyId: number;
    serviceTypeCode: string;
    featureValue: string;
}

export interface IResponseVoicePlansDTO {
    planId: number;
    productId: number;
    internalPlanId: number;
    planName: string;
    serviceTypeName: string;
    voicePlanType: string;
    providerId: number;
    providerCode: string;
    providerName: string;
    providerLogo: string;
    seatsType: string;
    chatImPrice: number;
    meetVideoPrice: number;
    seatsTypePrice: number;
    equipmentPrice: number;
    equipmentId: number;
    equipmentName: string;
    voiceFeatureName: string;
    term: number;
    calculatedPrice: number;
    BV_PRIM_AUTO_ATT: number;
    BV_CUSTOM_MUSIC: number;
    BV_VOIMAIL_TRANSC: number;
    BV_RECEP_CONSOLE: number;
    BV_TOLL_FREE_NUMBER: number;
    BV_SIP_TRU_METER: number;
    BV_SIP_TRU_UNLIMITED: number;
    BV_ADDITIONAL_DIDS: number;
    numberOfSeats: number;
    learnMore: string;
}

export interface IResponseVoiceEquipmentDTO {
    equipmentId: number;
    equipmentName: string;
    price: number;
    term: number;
    parentProductId: number;
    quantity: number;
}

export interface IResponseCarriersDTO {
    id: number;
    name: string;
    code: string;
    active: boolean;
    imageBase64: string;
}

export interface IResponseProductPropertyDTO {
    id: number;
    productId: number;
    propertyId: number;
    value: string;
    valueLong: string;
    propertyCode: string;
}

export interface IRequestSurveyDTO {
    search: boolean;
    encryptOrderId: string;
    planTypeCode: EnumPlanType;
    arrayVisitorSearchId: number[];
    visitorId: number;
}

export interface ISurveyDTO {
    numPages: number;
    pages: IPageSurveyDTO[];
    orderId: number;
    disabledTBI: boolean;
    encryptOrderId: string;
}

export interface IPageSurveyDTO {
    numPage: number;
    questionsDTO: IQuestionDTO[];
}

export interface ISurveySearchDTO {
    orderId: number | null;
    surveyQuestionSectionDTO: ISurveyQuestionSectionDTO[];
    surveyQuestionsResponse: ISurveyQuestionResponseDTO[];
    orderAddressesDTO: IOrderAddressesDTO[];
    planTypeCode?: EnumPlanType | null;
    planVoice?: IResponseVoicePlansDTO | undefined;
    featuresPlan?: IOrderFeatureDTO[] | [];
    equipmentsVoice?: IOrderVoiceEquipmentDTO[];
    numberOfSeats: number;
}

export interface ISurveyQuestionSectionDTO {
    id: number;
    name: string;
    position: number;
}

export interface ISurveyQuestionResponseDTO {
    questionId: number;
    label: string;
    value: string;
    section: number;
}

export interface IQuestionDTO {
    id: number;
    page: number;
    position: number;
    label: string;
    type: string;
    required: boolean;
    questionOptionsDTO: IQuestionOptionDTO[];
    defaultValue: string;
    isComplete: boolean | null;
    value: string;
    internet: boolean | null;
    voice: boolean | null;
}

export interface IQuestionOptionDTO {
    id: number;
    questionId: number;
    position: number;
    label: string;
}

export interface ISmartyResponseDTO {
    city: string | '';
    entries: number | 0;
    secondary: string | '';
    state: string | '';
    street_line: string | '';
    zipcode: string | '';
}

export interface ISmartyDetailResponseDTO {
    delivery_line_1: string;
    last_line: string;
    delivery_point_barcode: string;
    primary_number: string;
    street_predirection: string;
    street_name: string;
    street_suffix: string;
    city_name: string;
    default_city_name: string;
    state_abbreviation: string;
    zipcode: string;
    plus4_code: string;
    delivery_point: string;
    delivery_point_check_digit: string;
    json: string;
}

export interface IParametersResponseDTO {
    CALEndPoint: string | '';
    FindYourPerfectSpeed: string | '';
}

export interface ITbiOrderResponseDTO {
    id: number | null;
    date: string | '';
    companyName: string | '';
    name: string | '';
    email: string | '';
    phone: string | '';
    planType: string | '';
    planName: string | '';
    planTotal: number | null;
    url: string | '';
    orderAddressId: number | -1;
    status: string | '';
    referred: string | '';
}

export interface ICredentialsRequesDTO {
    user: string | null;
    password: string | '';
}

export interface IOrderFeatureDTO {
    orderId: number | null;
    featureId: number;
    description: string;
    featureNumber: number;
    price: number;
}

export interface IOrderVoiceEquipmentDTO {
    orderId: number | null;
    equipmentId: number;
    equipmentName: string;
    equipmentNumber: number;
    equipmentPrice: number;
}

export interface IRequestVoicePlansDTO {
    city: string;
    viewPlansType: EnumViewPlansType;
}

export interface IRequestAccountLoginDTO {
    email: string;
    password: string;
}

export interface IRequestAccountForgotPasswordDTO {
    email: string;
}

export interface IRequestAccountUserRegisterDTO {
    userName: string;
    email: string;
    password: string;
}

export interface IResponseAccountDTO {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
    isOk: boolean;
    message: string;
}

export interface IAuditVisitor {
    id: number;
    ip: string;
    address: string;
    stateAbbreviation: string;
    city: string;
    zipCode: string;
    latitude: number;
    longitude: number;
    search: IAuditVisitorSearch;
    searchMultiple: IAuditVisitorSearch[];
    idMultiple: number;
    searchFilter: IAuditVisitorSearchFilter;
    searchSortBy: IAuditVisitorSearchSortBy;
    searchCompare: IAuditVisitorSearchCompare;
    compareFeature: IAuditVisitorCompareFeature;
    compareEquipment: IAuditVisitorCompareEquipment;
    queryString: string;
    referred: string;
}

export interface IAuditVisitorSearch {
    id: number;
    visitorId: number;
    type: EnumSearchType;
    planTypeCode: string;
    address: string;
    stateAbbreviation: string;
    city: string;
    zipCode: string;
    seats: number;
    compares: IAuditVisitorSearchCompare[];
}

export interface IAuditVisitorSearchFilter {
    id: number;
    visitorSearchId: number;
    filterType: EnumFilterType;
    value1: string;
    value2: string;
    arraySearchsId: number[];
}

export interface IAuditVisitorSearchSortBy {
    id: number;
    visitorSearchId: number;
    sortBy: EnumSortBy;
    arraySearchsId: number[];
}

export interface IAuditVisitorSearchCompare {
    id: number;
    visitorSearchId: number;
    planId: number;
    internalPlanId: number;
    providerId: number;
    learnMore: number;
    remove?: boolean;
    codeProvider?: string;
}

export interface IAuditVisitorCompareFeature {
    id: number;
    visitorSearchCompareId: number;
    featureId: number;
    value: number;
    arrayCompares: IAuditVisitorSearchCompare[];
}

export interface IAuditVisitorCompareEquipment {
    id: number;
    visitorSearchCompareId: number;
    voiceEquipmentId: number;
    countEquipments: number;
    arrayCompares: IAuditVisitorSearchCompare[];
}

export interface IAuditReportDTO {
    reportType: number;
    FileName: string;
    FileType: string;
    FileData: string;
}

export interface IRequestCalculatorSendDTO {
    requestInternetPlans: IRequestInternetPlansDTO;
    employees: number;
    use: number;
    service: number;
    activity: number;
}

export interface IRequestCalculatorOrderDTO {
    requestInternetPlans: IRequestInternetPlansDTO;
    name: string;
    email: string;
    phone: string;
    company: string;
    zipCode: string;
}

export interface IResponseCalculatorSendDTO {
    downloadSpeed: number;
    broadbandOrDedicated: string;
    finalResult: string;
    lstCarriers: IResponseCarriersDTO[];
    lstProductProperties: IResponseProductPropertyDTO[];
    plans: IResponseInternetPlansDTO[];
}

export interface IRequestClickToDialTBIDTO {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    queryString: string;
    referred: string;
}


