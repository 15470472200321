import React from "react";

interface IVoicePlanLargeMobileFootProps {
  showMoreDetails: boolean;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const VoicePlanLargeMobileFoot: React.FC<IVoicePlanLargeMobileFootProps> = ({
  showMoreDetails,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  return (
    <>
      <div className="col-12 p-2">
        <div>
          <span className="card-view-call" onClick={onClickShowMoreDetails}>
            View more details
            <i
              className={
                !showMoreDetails
                  ? "fa fa-chevron-down view-more-icon"
                  : "fa fa-chevron-up view-more-icon"
              }
              aria-hidden="true"
            ></i>
          </span>
        </div>
        {/* <div className="card-center-call p-2">
          <p className="card-view-call">
            Call to Get Advice
            <br />
            <b>1.844.947.7737</b>
          </p>
        </div> */}
        <div>
          <button
            type="button"
            className="btn card-button-quote-m"
            onClick={onClickOrderNow}
          >
            {isCompare ? "ADD TO COMPARE" : "Order Now"}
          </button>
        </div>
      </div>
    </>
  );
};

export default VoicePlanLargeMobileFoot;
