import React from "react";

import {
  EnumPropertyId,
  IResponseInternetPlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";

interface IInternetPlanLargeMobileExpandedProps {
  plan: IResponseInternetPlansDTO;
}

const InternetPlanLargeMobileExpanded: React.FC<
  IInternetPlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals, setIGlobals] = useGlobalState("globals");

  const onClickMoreDetails = (pPlan: IResponseInternetPlansDTO) => {
    setIGlobals({
      ...iGlobals,
      planLearMore: pPlan,
    });

    setTimeout(function () {
      window.open(iPlanFilterParams.baseUrl + "learnmore", "_blank");
    }, 100);
  };

  return (
    <>
      <div className="col-12 p-2">
        <div className="card-icon-install mx-auto d-block" />
        <div className="card-view-title">INSTALLATION</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.INSTALLATION
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-dedicated mx-auto d-block" />
        <div className="card-view-title">
          DEDICATED
          <br />/ SHARED INTERNET
        </div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.DEDICATED_SHARED_INTERNET
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-support mx-auto d-block" />
        <div className="card-view-title">TECH SUPPORT</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.TECH_SUPPORT
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-technology mx-auto d-block" />
        <div className="card-view-title">TECHNOLOGY</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.TECHNOLOGY
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-staticip mx-auto d-block" />
        <div className="card-view-title">STATIC IP</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.STATIC_IP
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-symmetrical mx-auto d-block" />
        <div className="card-view-title">
          SYMMETRICAL
          <br />/ ASYMMETRICAL
          <br />
          CONNECTION
        </div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.SYM_ASYM_CONNECTION
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-price mx-auto d-block" />
        <div className="card-view-title">PRICE GUARANTEE</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 card-tr-title p-2 mobile-espace">
        RECOMMENDED FOR
      </div>
      <div className="col-12 text-center p-2 ">
        <div className="card-recommended-scroll">
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.RECOMMENDED_FOR
            )?.value
          }
        </div>
        {/* <div className="card-view-call" onClick={onClickSeeMoreRecommended}>
                See more <br />
                <i
                    className={
                    seeMoreRecommended ? "fa fa-chevron-up" : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                ></i>
                </div> */}
      </div>
      <hr className="card-hr" />
      <div className="col-12 card-tr-title p-2 mobile-espace">
        WHY THIS CARRIER
      </div>
      <div className="col-12 text-center p-2">
        <div className="card-recommended-scroll">
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
            )?.value
          }
        </div>
        {/* <div className="card-view-call" onClick={onClickSeeMoreWhyCarrier}>
                See more <br />
                <i
                    className={
                    seeMoreWhyCarrier ? "fa fa-chevron-up" : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                ></i>
                </div> */}
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <span
          className="card-view-see-even"
          onClick={() => onClickMoreDetails(plan)}
        >
          See even more details
        </span>
        {/* <i className="fa fa-chevron-down" aria-hidden="true"></i> */}
      </div>
    </>
  );
};

export default InternetPlanLargeMobileExpanded;
