import React, { useState } from "react";

import {
  EnumPropertyId,
  IResponseBundlePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";

interface IBundlePlanLargeMobileExpandedProps {
  plan: IResponseBundlePlansDTO;
}

const BundlePlanLargeMobileExpanded: React.FC<
  IBundlePlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iGlobals] = useGlobalState("globals");

  const [seeMoreTechSupport, setSeeMoreTechSupport] = useState(false);
  const [seeMoreSalesDescription, setSeeMoreSalesDescription] = useState(false);

  const onClickSeeMoreTechSupport = () => {
    setSeeMoreTechSupport(!seeMoreTechSupport);
  };

  const onClickSeeMoreSalesDescription = () => {
    setSeeMoreSalesDescription(!seeMoreSalesDescription);
  };

  return (
    <>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-check-technology mx-auto d-block" />
        <div className="card-view-title">PROFESSIONAL INSTALLATION</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.INSTALLATION
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-equipment mx-auto d-block" />
        <div className="card-view-title">EQUIPMENT FEE</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.EQUIPMENT_FEE
            )?.value
          }
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-tech-support mx-auto d-block" />
        <div className="card-view-title">TECH SUPPORT</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.TECH_SUPPORT
            )?.value
          }
        </div>
        {/* <div className="card-view-call" onClick={onClickSeeMoreTechSupport}>
          See more
          <br />
          <i
            className={
              seeMoreTechSupport ? "fa fa-chevron-up" : "fa fa-chevron-down"
            }
            aria-hidden="true"
          />
        </div> */}
      </div>
      <hr className="card-hr" />
      <div className="col-12 p-2">
        <div className="card-icon-carrier mx-auto d-block" />
        <div className="card-view-title">WHY THIS CARRIER</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
            )?.value
          }
        </div>
        {/* <div
          className="card-view-call"
          onClick={onClickSeeMoreSalesDescription}
        >
          See more
          <br />
          <i
            className={
              seeMoreSalesDescription
                ? "fa fa-chevron-up"
                : "fa fa-chevron-down"
            }
            aria-hidden="true"
          />
        </div> */}
      </div>
      <hr className="card-hr" />
      <div className="col-12 text-center p-2 mobile-espace">
        <div className="card-view-title">SALES DESCRIPTION</div>
      </div>
      <div className="col-12 text-center p-2">
        <div className="card-recommended-scroll">
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.SALES_DESCRIPTION
            )?.value
          }
        </div>
        {/* <div className="card-view-call" onClick={onClickSeeMoreWhyCarrier}>
          See more
          <br />
          <i
            className={
              seeMoreWhyCarrier ? "fa fa-chevron-up" : "fa fa-chevron-down"
            }
            aria-hidden="true"
          />
        </div> */}
      </div>
    </>
  );
};

export default BundlePlanLargeMobileExpanded;
