import React, { useEffect, useState } from "react";

import {
  EnumPropertyId,
  IResponseBundlePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IBundlePlanLargeMobileProps {
  plan: IResponseBundlePlansDTO;
  onChangeCompareCheckBox: (planId: number) => void;
}

const BundlePlanLargeMobile: React.FC<IBundlePlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  return (
    <div className="row align-middle">
      <div className="col-12 text-center p-2">
        {plan.providerLogo === "logoWiresurferDummy.png" ? (
          <img
            className="mx-auto d-block"
            src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
            alt=""
          />
        ) : (
          <img
            className="mx-auto d-block"
            src={`data:image/jpeg;base64,${
              iGlobals.carriers.find((c) => c.id === plan.providerId)
                ?.imageBase64
            }`}
            alt=""
          />
        )}
      </div>
      {/* <div className="col-6 text-center p-2">
                <div className="card-form-check">
                    <div className={iFilters.plansCompareBundle.find(planCompare => planCompare.idPlan === plan.planId) ? 'circle-check-on' : 'circle-check'} onClick={() => onChangeCompareCheckBox(plan.planId)} />
                    {
                        iFilters.plansCompareBundle && iFilters.plansCompareBundle.length >= 1
                            ? (
                                <span className="card-compareplan link-url" onClick={onClickCompareLink}>Compare Plan</span>
                            ) : (
                                <span className="card-compareplan">Compare Plan</span>
                            )
                    }
                </div>
            </div> */}
      {/* <div className="col-6 card-tr-title p-2 mobile-espace">NAME</div> */}
      <div className="col-12 text-center p-2">
        <span className="service-type-name-mobile">{plan.planName}</span>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">PRICE</div>
      <div className="col-6 text-center p-2">
        {/* <span id="card-price-bundles-mb" className="card-price">
          ${plan.planPrice.toFixed(2)}
        </span> */}
        <span className="voice-bolt">${plan.planPrice.toFixed(2)} mo</span>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">INTERNET SPEED</div>
      <div className="col-6 text-center p-2">
        <div>
          {/* <div className="card-icon-bolt-voice" /> */}
          <span id="card-speed-bundles-mb" className="voice-bolt">
            {plan.planDownloadSpeed >= 1000
              ? (plan.planDownloadSpeed / 1000).toFixed(0)
              : plan.planDownloadSpeed}
            {plan.planDownloadSpeed >= 1000 ? " Gbps" : " Mbps"}
          </span>
          {/* <div
            className={`${
              plan.planDownloadSpeed >= 1000
                ? "card-icon-gbps-voice"
                : "card-icon-mbps-voice"
            }`}
          /> */}
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">PHONE LINE</div>
      <div className="col-6 text-center p-2">
        <div>
          <span id="" className="voice-bolt">
            {
              iGlobals.productProperties.find(
                (pp) =>
                  pp.productId === plan.productId &&
                  pp.propertyCode === EnumPropertyId.PHONE_LINE
              )?.value
            }
          </span>
        </div>
      </div>
      <hr className="card-hr" />
      <div className="col-6 card-tr-title p-2">TERM</div>
      <div className="col-6 text-center p-2">
        <div>
          {/* <span className="card-max-term"></span> */}
          <div className="voice-bolt">{plan.planTerm} MONTHS</div>
        </div>
      </div>
    </div>
  );
};

export default BundlePlanLargeMobile;
